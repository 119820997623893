import React, { createContext, useEffect, useState } from "react"
import { CognitoUser } from "@aws-amplify/auth"
import { Amplify, Auth, API } from "aws-amplify"
import awsconfig from "../aws-exports"
import { Location } from "../constants/Location"
import { getOrganization } from "../graphql/queries"
import generateHandle from "../utils/generate-handle"
import { GraphQLOptions } from "@aws-amplify/api-graphql"
import { getTeammate } from "../graphql/queries"
import { Teammate, TeammateRoleType } from "../constants/Team"

Amplify.configure(awsconfig)

export interface Address {
  name?: string
  locality?: string
  postal_code?: string
  region?: string
  street_address?: string
  country?: string
  formatted?: string
  place_id?: string
}
export interface UserAttributes {
  address?: Address
  email: string
  phone_number?: string
  locale?: string
  name?: string
  family_name?: string
  given_name?: string
  picture?: string
  profile?: string
  sub?: string
  website?: string
  reCapthcaToken?: string
  "custom:storage"?: string
  "custom:creation_role"?: string
  "custom:organization"?: string
  "custom:organizationID"?: string
  "custom:creation_industry"?: string
  "custom:stripe_customer"?: string
}

export interface CognitoUserExt extends CognitoUser {
  attributes: UserAttributes
}

interface AuthState {
  cognitoUser: CognitoUserExt | undefined
  OTPChallenge: boolean
  OTPExpired: boolean
  sendOTP: (username: string) => Promise<void>
  confirmOTP: (otp: string) => Promise<void>
  signUp: (newUser: UserAttributes) => Promise<void>
  signOut: () => Promise<void>
  oneStepRegistration: (
    username: string,
    reCapthcaToken: string
  ) => Promise<void>
  updateUserAttributes: (attributes: any) => Promise<void>
  isUserSignedIn: () => Promise<boolean>
  getSignUpAddress: () => Promise<Location | undefined>
  authenticated: boolean
  getRedirectTarget: () => string
  setRedirectTarget: (target: string) => Promise<void>
  defaultAddress: Address | undefined
  isAdmin: boolean
  getCountryCode: () => Promise<string | undefined>
  countryCode: string
  clonedSite: boolean
}

export const AuthContext = createContext<AuthState>({
  cognitoUser: undefined,
  OTPChallenge: false,
  OTPExpired: false,
  confirmOTP: async () => {},
  sendOTP: async () => {},
  signUp: async () => {},
  signOut: async () => {},
  oneStepRegistration: async () => {},
  updateUserAttributes: async () => {},
  isUserSignedIn: async () => false,
  getSignUpAddress: async () => undefined,
  authenticated: false,
  getRedirectTarget: () => "",
  setRedirectTarget: async () => undefined,
  defaultAddress: undefined,
  isAdmin: false,
  getCountryCode: async () => "",
  countryCode: "",
  clonedSite: false,
})

interface AuthContextProps {
  children: React.ReactNode
}

function getRandomString(bytes: number) {
  const randomValues = new Uint8Array(bytes)
  window.crypto.getRandomValues(randomValues)
  return Array.from(randomValues).map(intToHex).join("")
}

function intToHex(nr: number) {
  return nr.toString(16).padStart(2, "0")
}

const AuthContextProvider = ({ children }: AuthContextProps) => {
  const [cognitoUser, setCognitoUser] = useState<CognitoUserExt>()
  const [OTPChallenge, setOTPChallenge] = useState<boolean>(false)
  const [OTPExpired, setOTPExpired] = useState<boolean>(false)
  const [authenticated, setAuthenticated] = useState<boolean>(false)
  const [destination, setDestination] = useState<string>("")
  const [defaultAddress, setDefaultAddress] = useState<Address>()
  const [isAdmin, setIsAdmin] = useState(false)
  const [countryCode, setCountryCode] = useState("US")
  const [clonedSite, setClonedSite] = useState(false)
  // useEffect(() => {
  //   checkSignedIn()
  // }, [setCognitoUser])

  useEffect(() => {
    const checkSignedIn = async () => {
      try {
        await checkForClone()
        const cognitoUserResponse = await Auth.currentAuthenticatedUser({
          bypassCache: true,
        })
        setCognitoUser(cognitoUserResponse)
        setAuthenticated(true)
        const signUpAddress = await getSignUpAddress()
        setDefaultAddress(signUpAddress)
        await checkUserAdmin(cognitoUserResponse)
      } catch (error) {
        setAuthenticated(false)
        setCognitoUser(undefined)
      }
    }

    // setDestination(window.localStorage.getItem("redirectTarget") || "/")
    // Hub.listen("auth", checkSignedIn)
    checkSignedIn()
  }, [])

  useEffect(() => {
    if (destination)
      window.localStorage.setItem("redirectTarget", destination.toString())
  }, [destination])

  const checkUserAdmin = async (cognitoObject: CognitoUserExt) => {
    if (cognitoObject) {
      const orgHandle = await fetchHandle()
      if (
        cognitoObject?.attributes?.["custom:creation_role"] === "admin" &&
        generateHandle(cognitoObject?.attributes?.["custom:organization"]) ===
          orgHandle
      ) {
        setIsAdmin(true)
      } else {
        try {
          const teammate: Teammate | undefined = await getTeammateInfo()
          if (teammate) {
            if (
              teammate.roles.find(
                roleMap =>
                  roleMap.organizationID === `4035948d-763a-41e2-8600-1cdd71a68da5` &&
                  roleMap.roles.includes(TeammateRoleType.ADMIN)
              )
            ) {
              setIsAdmin(true)
              return
            }
          }
        } catch (err) {
          console.log("retrieving teammate error", err)
        }
        setIsAdmin(false)
      }
    }
  }

  const fetchHandle = async () => {
    const query = {
      query: getOrganization,
      variables: {
        id: `4035948d-763a-41e2-8600-1cdd71a68da5`,
      },
    }
    try {
      const {
        data: {
          getOrganization: { handle },
        },
      } = await (API.graphql(query) as Promise<any>)

      return handle
    } catch (error) {
      console.log("fetch Handle error", error)
    }
  }

  const checkForClone = async () => {
    const authMode: GraphQLOptions["authMode"] = "AWS_IAM"

    const query = {
      query: getOrganization,
      variables: {
        id: `4035948d-763a-41e2-8600-1cdd71a68da5`,
      },
      authMode: authMode,
    }
    try {
      const {
        data: {
          getOrganization: { cloned },
        },
      } = await (API.graphql(query) as Promise<any>)
      console.log("Is site a clone?:", cloned)
      setClonedSite(cloned === true)
      return cloned
    } catch (error) {
      console.log("check For Clone error", error)
    }
  }
  const setRedirectTarget = async (target: string) => {
    // console.log("setting redirect", target)
    setDestination(target)
  }

  const getRedirectTarget = () => {
    // console.log("Requesting redirect", destination)
    return destination
  }

  const isUserSignedIn = async () => {
    try {
      await Auth.currentAuthenticatedUser({ bypassCache: true })
      setAuthenticated(true)
      return true
    } catch (error) {
      setAuthenticated(false)
      setCognitoUser(undefined)
      return false
    }
  }

  const oneStepRegistration = async (
    username: string,
    reCapthcaToken: string
  ) => {
    try {
      await Auth.signUp({
        username: username,
        password: getRandomString(30),
        validationData: {
          v2InvisibleCaptcha: reCapthcaToken,
        },
      })
    } catch (e: any) {
      if (e.name === "UsernameExistsException") {
        console.log("Username Exists")
        // await sendOTP(username)
      } else {
        console.log("oneStepRegistration error", e)
        throw e
      }
    }
  }

  const getCountryCode = async () => {
    console.log("GETCOUNTRYCODECALLED")
    function isoCode(countryName: string | undefined) {
      if (countryName) {
        return countryName?.substring(0, 2).toUpperCase() || "US"
      } else {
        return "US"
      }
    }

    if (defaultAddress) {
      const newCode = isoCode(defaultAddress.country)
      setCountryCode(newCode)
      console.log("SignupAddress found with country code:", newCode)
      return newCode
    }

    return new Promise<string | undefined>((resolve, reject) => {
      let newCode = "XX"
      navigator.geolocation.getCurrentPosition(
        async position => {
          const lat = position.coords.latitude
          const long = position.coords.longitude
          const bdcApi = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${long}`
          const result = await fetch(bdcApi)
          const data = await result.json()
          if (data.countryCode) {
            newCode = data.countryCode
            setCountryCode(newCode)
            console.log("returning new code 1", newCode)
            resolve(newCode)
          }
        },
        err => {
          reject(err)
        }
      )
    }).catch(err => {
      console.log(err)
      return "XX"
    })

    // bdc_3e172647cb0d40a98f2b2399f5b4cf9f
  }

  const signUp = async (newUser: UserAttributes) => {
    try {
      await Auth.signUp({
        username: newUser.email,
        password: getRandomString(30),
        attributes: {
          email: newUser.email,
          phone_number: newUser.phone_number,
          name: newUser.name,
          family_name: newUser?.family_name,
          given_name: newUser?.given_name,
          address: JSON.stringify({
            locality: newUser.address?.locality,
            postal_code: newUser.address?.postal_code,
            region: newUser.address?.region,
            street_address: newUser?.address?.street_address,
            country: newUser?.address?.country,
            place_id: newUser.address?.place_id,
          }),
        },
        validationData: {
          v2InvisibleCaptcha: newUser.reCapthcaToken,
        },
      })
    } catch (e: any) {
      if (e.name === "UsernameExistsException") {
        console.log("Username Exists", e)
      } else {
        console.log("signup error", e)
        throw e
      }
    }
    window.localStorage.setItem("currentEmail", newUser.email.toLowerCase())
  }

  const getSignUpAddress = async () => {
    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()
      const { attributes } = cognitoUserResponse
      console.log("getSignUpAddress", attributes)
      if (attributes?.address?.length > 0) {
        const userAddressAttributes = JSON.parse(attributes.address)
        if (userAddressAttributes) {
          const location: Location = {
            name: userAddressAttributes?.street_address || "Customer address",
            street_address: userAddressAttributes?.street_address,
            locality: userAddressAttributes?.locality,
            region: userAddressAttributes?.region,
            country: userAddressAttributes?.country,
            postal_code: userAddressAttributes?.postal_code,
            place_id: userAddressAttributes?.place_id,
          }
          console.log("signUp location", location)
          return location
        }
      }
    } catch {}
    return undefined
  }

  const sendOTP = async (username: string) => {
    try {
      // await signOut()
      const cognitoUserResponse = await Auth.signIn(username)
      setOTPChallenge(true)
      setOTPExpired(false)
      setCognitoUser(cognitoUserResponse)
      window.localStorage.setItem("currentEmail", username)
    } catch (error) {
      throw error
    }
  }

  const getTeammateInfo = async () => {
    let authMode: GraphQLOptions["authMode"]
    let cognitoUserResponse: CognitoUserExt | undefined = undefined

    try {
      cognitoUserResponse = await Auth.currentAuthenticatedUser()

      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch {
      authMode = "AWS_IAM"
    }
    try {
      const getQuery = {
        query: getTeammate,
        variables: {
          id: cognitoUserResponse?.attributes?.sub,
        },
        authMode: authMode,
      }
      const query: any = await API.graphql(getQuery)
      const teammateRecord = query?.data?.getTeammate
      return teammateRecord
    } catch (err) {}
    return undefined
  }

  const confirmOTP = async (otp: string) => {
    let cognitoUserResponse
    try {
      // Send the answer to the User Pool
      // This will throw an error if it’s the 3rd wrong answer
      cognitoUserResponse = await Auth.sendCustomChallengeAnswer(
        cognitoUser,
        otp
      )
    } catch (error) {
      setOTPExpired(true)
      setOTPChallenge(false)
      setAuthenticated(false)
      throw {
        code: "NotAuthorizedException",
        message: "Your verification code has expired.",
        name: "NotAuthorizedException",
      }
    }

    // It we get here, the answer was sent successfully,
    // but it might have been wrong (1st or 2nd time)
    // So we should test if the user is authenticated now
    try {
      // This will throw an error if the user is not yet authenticated:
      await Auth.currentSession()
      setCognitoUser(cognitoUserResponse)
      setAuthenticated(true)
      checkUserAdmin(cognitoUserResponse)
      setOTPExpired(false)
    } catch (error) {
      throw {
        code: "NotAuthorizedException",
        message: "Invalid Access Code.",
        name: "NotAuthorizedException",
      }
    }
  }

  const signOut = async () => {
    try {
      await Auth.signOut({ global: true })
      // console.log("sign out: success")
      setCognitoUser(undefined)
      setAuthenticated(false)
    } catch (error) {
      await Auth.signOut()
      // console.log("error signing out: ", error)
    } finally {
      if (cognitoUser) {
        setCognitoUser(undefined)
      }
      if (authenticated) {
        setAuthenticated(false)
      }
      setIsAdmin(false)
      setOTPChallenge(false)
    }
  }

  const updateUserAttributes = async (attributes: any) => {
    try {
      await Auth.updateUserAttributes(cognitoUser, attributes)
      setCognitoUser(user => {
        if (user) {
          user.attributes = { ...user?.attributes, ...attributes }
          return user
        }
      })
    } catch (error) {
      console.log("update profile error", error)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        cognitoUser,
        OTPChallenge,
        OTPExpired,
        sendOTP,
        confirmOTP,
        signUp,
        signOut,
        oneStepRegistration,
        updateUserAttributes,
        isUserSignedIn,
        getSignUpAddress,
        authenticated,
        getRedirectTarget,
        setRedirectTarget,
        defaultAddress,
        isAdmin,
        getCountryCode,
        countryCode,
        clonedSite,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
