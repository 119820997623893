exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catalog-tsx": () => import("./../../../src/pages/catalog.tsx" /* webpackChunkName: "component---src-pages-catalog-tsx" */),
  "component---src-pages-component-catalog-tsx": () => import("./../../../src/pages/component/catalog.tsx" /* webpackChunkName: "component---src-pages-component-catalog-tsx" */),
  "component---src-pages-component-orders-tsx": () => import("./../../../src/pages/component/orders.tsx" /* webpackChunkName: "component---src-pages-component-orders-tsx" */),
  "component---src-pages-component-sign-in-tsx": () => import("./../../../src/pages/component/sign-in.tsx" /* webpackChunkName: "component---src-pages-component-sign-in-tsx" */),
  "component---src-pages-component-sign-up-tsx": () => import("./../../../src/pages/component/sign-up.tsx" /* webpackChunkName: "component---src-pages-component-sign-up-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-orders-tsx": () => import("./../../../src/pages/orders.tsx" /* webpackChunkName: "component---src-pages-orders-tsx" */),
  "component---src-pages-schedule-tsx": () => import("./../../../src/pages/schedule.tsx" /* webpackChunkName: "component---src-pages-schedule-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-buy-product-tsx": () => import("./../../../src/templates/buy-product.tsx" /* webpackChunkName: "component---src-templates-buy-product-tsx" */),
  "component---src-templates-complete-booking-tsx": () => import("./../../../src/templates/complete-booking.tsx" /* webpackChunkName: "component---src-templates-complete-booking-tsx" */),
  "component---src-templates-component-buy-product-tsx": () => import("./../../../src/templates/component-buy-product.tsx" /* webpackChunkName: "component---src-templates-component-buy-product-tsx" */),
  "component---src-templates-component-complete-booking-tsx": () => import("./../../../src/templates/component-complete-booking.tsx" /* webpackChunkName: "component---src-templates-component-complete-booking-tsx" */),
  "component---src-templates-component-product-tsx": () => import("./../../../src/templates/component-product.tsx" /* webpackChunkName: "component---src-templates-component-product-tsx" */),
  "component---src-templates-component-purchase-complete-tsx": () => import("./../../../src/templates/component-purchase-complete.tsx" /* webpackChunkName: "component---src-templates-component-purchase-complete-tsx" */),
  "component---src-templates-component-rebook-product-tsx": () => import("./../../../src/templates/component-rebook-product.tsx" /* webpackChunkName: "component---src-templates-component-rebook-product-tsx" */),
  "component---src-templates-downloads-tsx": () => import("./../../../src/templates/downloads.tsx" /* webpackChunkName: "component---src-templates-downloads-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-purchase-complete-tsx": () => import("./../../../src/templates/purchase-complete.tsx" /* webpackChunkName: "component---src-templates-purchase-complete-tsx" */),
  "component---src-templates-rebook-product-tsx": () => import("./../../../src/templates/rebook-product.tsx" /* webpackChunkName: "component---src-templates-rebook-product-tsx" */)
}

