import React, { createContext, useContext, useEffect, useState } from "react"
import { Amplify, API, Auth } from "aws-amplify"
import awsconfig from "../aws-exports"
import { GraphQLOptions } from "@aws-amplify/api-graphql"
import {
  azingoCalendarQuery,
  getPrice,
  getTimeSlot,
  listBookingRestrictions,
  bookingsByOrganization,
  contractsByOrganization,
} from "../graphql/queries"
import {
  azingoBookingTeamAvailability,
  azingoBookingTimeSlot,
  azingoBookingNewCustomer,
  updateBooking,
} from "../graphql/mutations"
import moment from "moment"
import { ServiceContext } from "./service-context"
import { CartContext } from "./cart-context"
import {
  Booking,
  BookingDisplay,
  PurchaseDisplay,
  BookingResults,
  BookingResponse,
  RegistrationProps,
  BookingFailureReason,
} from "../constants/Booking"
import {
  TeamAvailability,
  ChunkedAvailability,
  AvailabilityInfo,
} from "../constants/TeamAvailability"
import { Location, ServiceLocationType } from "../constants/Location"
import { Contract } from "../constants/Contract"
import { TimeSlot, TimeSlotInfo } from "../constants/TimeSlot"
import {
  BillingTypeEnum,
  BookingTypeEnum as BookingServiceType,
  Service,
  ServiceBookingRestrictions,
} from "../constants/Service"
import { PurchaseSession } from "../constants/Cart"
import stripTypename from "../utils/strip-typename"

Amplify.configure(awsconfig)

export interface CalendarInfo {
  teamAvailabilities?: TeamAvailability[]
  timeSlots?: TimeSlot[]
  bookings?: Booking[]
  contracts?: Contract[]
  chunkedAvailabilities?: ChunkedAvailability[]
}

interface BookingState {
  getFilteredBookingInfo: (serviceListArray: string[]) => Promise<void>
  getBookings: (contractID: string) => Promise<BookingDisplay[] | undefined>
  bookingInfo?: CalendarInfo
  contractInfo: Contract[] | undefined
  userBookings: BookingDisplay[] | undefined
  todaysServiceIds: string[] | undefined
  setTodaysServiceIds: (serviceList: string[]) => void
  setChosenService: (service: string) => void
  refreshContractInfo: () => Promise<void>
  chosenService: string | undefined
  setChosenDateKey: (service: string) => void
  chosenDateKey: string | undefined
  timeSlots: TimeSlotInfo[] | undefined
  availabilities: AvailabilityInfo[] | undefined
  availabilityWindows: AvailabilityInfo[] | undefined
  submitBooking: (
    timeSlot: TimeSlotInfo,
    chosenLocation: Location | undefined,
    teamID: string,
    startDate: string,
    endDate: string
  ) => Promise<BookingResponse | undefined>
  submitScheduled: (
    timeSlotIds: string[],
    serviceID: string,
    location: Location | undefined
  ) => Promise<BookingResponse | undefined>
  purchaseDisplay: PurchaseDisplay[] | undefined
  registerCustomer: (props: RegistrationProps) => Promise<[]>
  loadingCalendar: boolean
  setCalendarReloadRequested: (reload: boolean) => void
  getPurchaseCustomerID: (userID: string | undefined) => string | undefined
  fetchTimeSlot: (timeSlotID: string) => Promise<TimeSlot | undefined>
  fetchBooking: (timeSlotID: string) => Promise<Booking[] | undefined>
  cancelBooking: (bookingID: string, reschedule: boolean) => Promise<boolean>
  fetchBookingRestrictions: (
    contractID: string
  ) => Promise<ServiceBookingRestrictions[] | undefined>
  readyToConfirmBooking: (purchaseSession: PurchaseSession) => boolean
  failureReasons: BookingFailureReason[]
}

interface BookingContextProps {
  children: React.ReactNode
}

export const BookingContext = createContext<BookingState>({
  bookingInfo: undefined,
  getFilteredBookingInfo: async () => {},
  getBookings: async () => [],
  contractInfo: [],
  todaysServiceIds: [],
  setTodaysServiceIds: () => {},
  setChosenService: () => {},
  refreshContractInfo: async () => {},
  chosenService: "",
  setChosenDateKey: () => {},
  chosenDateKey: "",
  timeSlots: [],
  availabilities: [],
  availabilityWindows: [],
  submitBooking: async () => undefined,
  submitScheduled: async () => undefined,
  purchaseDisplay: undefined,
  registerCustomer: async () => [],
  loadingCalendar: false,
  setCalendarReloadRequested: () => {},
  getPurchaseCustomerID: () => undefined,
  fetchTimeSlot: async () => undefined,
  fetchBooking: async () => undefined,
  cancelBooking: async () => false,
  fetchBookingRestrictions: async () => undefined,
  readyToConfirmBooking: () => false,
  failureReasons: [],
  userBookings: undefined,
})

const BookingContextProvider = ({ children }: BookingContextProps) => {
  const { serviceList, getServicesInfo } = useContext(ServiceContext)
  const { clearPurchaseSession } = useContext(CartContext)
  const [bookingInfo, setBookingInfo] = useState<CalendarInfo>()
  const [userBookings, setUserBookings] = useState<BookingDisplay[]>()
  const [contractInfo, setContractInfo] = useState<Contract[] | undefined>()
  const [todaysServiceIds, setTodaysServiceIds] = useState<string[]>()
  const [chosenService, setChosenService] = useState<string>()
  const [chosenDateKey, setChosenDateKey] = useState<string>()
  const [timeSlots, setTimeSlots] = useState<TimeSlotInfo[]>()
  const [availabilities, setAvailabilities] = useState<AvailabilityInfo[]>()
  const [availabilityWindows, setAvailabilityWindows] =
    useState<AvailabilityInfo[]>()
  const [purchaseDisplay, setPurchaseDisplay] = useState<PurchaseDisplay[]>()
  const [loadingCalendar, setLoadingCalendar] = useState<boolean>(false)
  const [calendarReloadRequested, setCalendarReloadRequested] =
    useState<boolean>(false)
  const [failureReasons, setFailureReasons] = useState<BookingFailureReason[]>(
    []
  )

  // const filterServices = (serviceList: string[]) => {
  //   setServiceIds(serviceList)
  // }

  // const getDateKey = (fullDate: string | undefined) => {
  //   if (fullDate) {
  //     return moment(fullDate).local().format("YYYY-MM-DD")
  //   } else {
  //     return undefined
  //   }
  // }

  const getDisplayTime = (fullDate: string | undefined) => {
    if (fullDate) {
      return moment(fullDate).local().format("h:mm A")
    } else {
      return undefined
    }
  }

  const getPurchases = async () => {
    let purchaseArray: PurchaseDisplay[] = []
    return new Promise(resolve => {
      try {
        contractInfo?.map(async contract => {
          // if (contract.active) {
          const serviceItem = serviceList?.filter(
            service => service.id === contract.serviceID
          )[0]
          if (serviceItem) {
            const selectedPrice = await getContractPrice(contract.priceID)
            let purchaseItem: PurchaseDisplay = {
              serviceName: serviceItem.name,
              serviceDescription: serviceItem?.description,
              serviceHandle: serviceItem.handle,
              serviceUrl: serviceItem.thumbnail,
              purchaseDate: contract.createdAt,
              purchaseAmount: selectedPrice?.amount || 0,
              purchaseReference: contract.id,
              booking: await getBookings(contract.id),
              stripeCustomerID: contract.stripeCustomerID,
              stripePaymentIntentID: contract.stripePaymentIntentID,
              stripeSubscriptionID: contract.stripeSubscriptionID,
              contractID: contract.id,
            }
            purchaseArray.push(purchaseItem)
          }
          // }
        })
        setPurchaseDisplay(purchaseArray)
        resolve(true)
      } catch (error) {
        resolve(false)
      }
    })
  }

  const getContractPrice = async (priceID: string) => {
    let authMode: GraphQLOptions["authMode"]

    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()

      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch {
      authMode = "AWS_IAM"
    }
    try {
      const getQuery = {
        query: getPrice,
        variables: {
          id: priceID,
        },
        authMode: authMode,
      }
      const query: any = await API.graphql(getQuery)
      const priceRecord = query?.data?.getPrice

      return priceRecord
    } catch (err) {}
    return undefined
  }

  const getServiceNameByID = (
    serviceID: string,
    services: Service[] | undefined
  ) => {
    if (services) {
      const serviceRecord = services.find(service => service.id === serviceID)
      if (serviceRecord) {
        return serviceRecord.name
      }
    }
    return "Service"
  }

  const getBookings = async (contractID: string) => {
    let bookingsArray: BookingDisplay[] = []
    let bookingEntry: any = {}
    return new Promise<BookingDisplay[] | undefined>((resolve, reject) => {
      try {
        console.log("anyBookingInfo?", bookingInfo)
        bookingInfo?.bookings
          ?.filter(booking => booking.contractID === contractID)
          ?.map(async booking => {
            bookingEntry.bookingID = booking.id
            bookingEntry.bookingStatus = booking.type
            bookingEntry.bookingDate = booking.bookingDate
            bookingEntry.serviceName = getServiceNameByID(
              booking.serviceID,
              serviceList
            )
            bookingEntry.scheduledEndDate = booking?.timeSlot?.endDate
            bookingEntry.location = booking?.location
            if (booking.timeSlotID) {
              const bookingTimeSlot = await fetchTimeSlot(booking.timeSlotID)
              if (bookingTimeSlot) {
                bookingEntry.scheduledDate = JSON.parse(
                  JSON.stringify(bookingTimeSlot)
                ).startDate
                bookingEntry.scheduledTime = getDisplayTime(
                  bookingTimeSlot.startDate
                )
              }
            }
            bookingEntry.timeSlotID = booking.timeSlotID
            console.log("BookingRecord", bookingEntry)
            bookingsArray.push(bookingEntry)
            bookingEntry = {}
          })

        resolve(bookingsArray)
      } catch (error) {
        reject(undefined)
      }
    })
  }

  const generateAppointmentArraysFromCalendarData = async (
    calandarInfo: CalendarInfo
  ) => {
    return new Promise(resolve => {
      const timeSlotData = calandarInfo.timeSlots
      const availabilities = calandarInfo.chunkedAvailabilities
      const availabilityWindows = calandarInfo.teamAvailabilities
      const bookingData = calandarInfo.bookings

      let timeSlotArray: TimeSlotInfo[] = []
      let bookingArray: BookingDisplay[] = []
      let availabilityArray: AvailabilityInfo[] = []
      let availabilityWindowArray: AvailabilityInfo[] = []

      if (timeSlotData) {
        timeSlotData.forEach(timeslot => {
          let timeSlotObject: TimeSlotInfo = {}
          const bookingDuration =
            timeslot?.endDate && timeslot?.startDate
              ? moment(timeslot.endDate).diff(timeslot.startDate, "minutes")
              : 0
          timeSlotObject.timeSlotID = timeslot.id
          timeSlotObject.displayTime = getDisplayTime(timeslot?.startDate)
          timeSlotObject.startDate = timeslot?.startDate
          timeSlotObject.endDate = timeslot?.endDate
          timeSlotObject.status = timeslot.type
          timeSlotObject.location = timeslot.location
          timeSlotObject.autoRegistered = timeslot?.autoRegistered
          timeSlotObject.registrationType = timeslot?.registrationType
          timeSlotObject.deliveryOptions = timeslot?.deliveryOptions
          timeSlotObject.duration = bookingDuration
          timeSlotObject.maxAttendees = timeslot?.maxAttendees
          timeSlotObject.attendingContracts = timeslot?.attendingContracts
          timeSlotObject.baseType = timeslot.baseType
          timeSlotObject.serviceID = timeslot.serviceID
          timeSlotObject.teamID = timeslot?.teamID
          timeSlotArray.push(timeSlotObject)
        })
      }

      if (availabilities) {
        availabilities.forEach(availability => {
          let availabilityObject: AvailabilityInfo = {
            id: "",
            baseType: "TeamAvailability",
            startDate: "",
            endDate: "",
            serviceID: "",
          }
          const availabilityDuration =
            availability?.endDate && availability?.startDate
              ? moment(availability.endDate).diff(
                  availability.startDate,
                  "minutes"
                )
              : 0
          availabilityObject.startDate = availability.startDate
          availabilityObject.displayTime = getDisplayTime(
            availability.startDate
          )
          availabilityObject.endDate = availability.endDate
          availabilityObject.locations = availability?.locations
          availabilityObject.id = availability.id
          availabilityObject.serviceID = availability.serviceID
          availabilityObject.duration = availabilityDuration
          availabilityObject.teamIDs = availability.teamIDs
          availabilityArray.push(availabilityObject)
        })
      }
      if (availabilityWindows) {
        availabilityWindows.forEach(availability => {
          let availabilityObject: AvailabilityInfo = {
            id: "",
            baseType: "TeamAvailability",
            startDate: "",
            endDate: "",
            serviceID: "",
          }

          availabilityObject.startDate = availability.startDate
          availabilityObject.displayTime = getDisplayTime(
            availability.startDate
          )
          availabilityObject.endDate = availability.endDate
          availabilityObject.locations = availability?.locations
          availabilityObject.id = availability.id
          availabilityObject.serviceID = availability.serviceID
          availabilityObject.duration = availability.bookingDuration
          availabilityObject.teamIDs = availability.teamIDs
          availabilityWindowArray.push(availabilityObject)
        })
      }
      if (bookingData) {
        bookingData.forEach(booking => {
          let bookingObject: BookingDisplay = {
            bookingID: "",
            scheduledDate: "",
            scheduledTime: "",
            bookingStatus: "",
            timeSlotID: "",
          }
          const serviceName =
            serviceList?.find(service => service.id === booking.serviceID)
              ?.name || ""
          bookingObject.bookingDate = booking.bookingDate
          bookingObject.bookingStatus = booking.type
          bookingObject.location = booking.location
          if (booking.timeSlot) {
            bookingObject.scheduledDate = JSON.parse(
              JSON.stringify(booking.timeSlot)
            ).startDate
            bookingObject.scheduledEndDate = JSON.parse(
              JSON.stringify(booking.timeSlot)
            ).endDate
            bookingObject.scheduledTime =
              getDisplayTime(booking.timeSlot.startDate) || ""
          }
          bookingObject.serviceName = serviceName
          bookingObject.timeSlotID = booking.timeSlotID
          bookingArray.push(bookingObject)
        })
      }
      if (availabilityArray && availabilityArray.length === 0) {
        console.log("Availabilities are empty!!!")
      }
      setTimeSlots(timeSlotArray)
      setUserBookings(bookingArray)
      setAvailabilities(availabilityArray)
      setAvailabilityWindows(availabilityWindowArray)
      resolve(availabilityArray)
    })
  }

  const cancelBooking = async (bookingID: string, reschedule: boolean) => {
    let authMode: GraphQLOptions["authMode"]

    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()

      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch {
      authMode = "AWS_IAM"
    }
    try {
      const updateQuery = {
        query: updateBooking,
        variables: {
          input: {
            id: bookingID,
            type: "CANCELED_CONTRACT",
            suppressEmail: reschedule || false,
          },
        },
        authMode: authMode,
      }
      // console.log("bookingUpdateQuery", updateQuery)
      const {
        data: { updateBooking: response },
      } = await (API.graphql(updateQuery) as Promise<any>)
      if (response && response.status == 200) {
        return true
      }
    } catch (err) {
      console.log("booking Cancel Error", err)
      return false
    }
    return false
  }

  const fetchTimeSlot = async (timeSlotID: string) => {
    let authMode: GraphQLOptions["authMode"]

    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()

      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch {
      authMode = "AWS_IAM"
    }
    try {
      const getQuery = {
        query: getTimeSlot,
        variables: {
          id: timeSlotID,
        },
        authMode: authMode,
      }
      const query: any = await API.graphql(getQuery)
      const timeSlot: TimeSlot = query?.data?.getTimeSlot
      return timeSlot
    } catch (err) {
      return undefined
    }
  }

  const fetchBooking = async (timeSlotID: string) => {
    let authMode: GraphQLOptions["authMode"]

    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()

      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch {
      authMode = "AWS_IAM"
    }
    try {
      let items: Booking[] = []

      const query: any = await API.graphql({
        query: bookingsByOrganization,
        variables: {
          organizationID: `4035948d-763a-41e2-8600-1cdd71a68da5`,
          filter: {
            timeSlotID: {
              eq: timeSlotID,
            },
          },
        },
        authMode: authMode,
      })

      items = query?.data?.bookingsByOrganization?.items

      console.log("fetch bookings items", items)
      return items
    } catch (err) {
      console.log("fetch bookings error", err)
      return []
    }
  }

  const fetchBookingRestrictions = async (contractID: string) => {
    let authMode: GraphQLOptions["authMode"]

    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()

      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch {
      authMode = "AWS_IAM"
    }
    try {
      let nextToken = null
      let items: ServiceBookingRestrictions[] = []
      do {
        const query: any = await API.graphql({
          query: listBookingRestrictions,
          variables: {
            organizationID: { eq: `4035948d-763a-41e2-8600-1cdd71a68da5` },
            filter: {
              contractID: {
                eq: contractID,
              },
            },
            nextToken,
          },
          authMode: authMode,
        })

        items = items.concat(query?.data?.listBookingRestrictions?.items)
        nextToken = query?.data?.listBookingRestrictions?.nextToken
      } while (nextToken)
      return items
    } catch (err) {
      return []
    }
  }

  const getPurchaseCustomerID = (userID: string | undefined) => {
    let stripeCustomerID: string | undefined
    let contractsArray: Contract[] = []
    if (contractInfo) {
      contractInfo &&
        contractInfo.map(contract => {
          contractsArray.push(contract)
        })
    }
    // get stripeCustomerID if it exists
    if (contractsArray && contractsArray.length > 0) {
      stripeCustomerID = contractsArray.find(
        contract => contract.userID === userID && contract?.stripeCustomerID
      )?.stripeCustomerID
    }
    return stripeCustomerID
  }

  const getServiceContracts = async (serviceID: string) => {
    let possibleToken
    let currentUserID
    try {
      possibleToken = (await Auth.currentSession()).getIdToken().getJwtToken()
      const currentUserInfo = await Auth.currentUserInfo()
      currentUserID = currentUserInfo?.attributes?.sub
      console.log("currentUserID", currentUserID)
      console.log("currentServiceID", serviceID)
    } catch (error) {
      return []
    }
    try {
      const requestInfo = {
        headers: {
          Authorization: possibleToken,
          "Content-Type": "application/json",
        },
        body: {
          action: "list-user-service-contracts",
          userID: currentUserID,
          serviceID: serviceID,
        },
      }
      if (serviceID && currentUserID) {
        const resp = await API.post("azingorest", "/contract", requestInfo)
        return resp.body
      } else return []
    } catch (error) {
      return []
    }
  }

  const refreshContractInfo = async () => {
    let authMode: GraphQLOptions["authMode"]
    let cognitoUserResponse
    try {
      cognitoUserResponse = await Auth.currentAuthenticatedUser()

      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch {
      authMode = "AWS_IAM"
    }
    try {
      let items: Contract[] = []
      const query: any = await API.graphql({
        query: contractsByOrganization,
        variables: {
          organizationID: `4035948d-763a-41e2-8600-1cdd71a68da5`,
          filter: {
            userID: {
              eq: cognitoUserResponse.attributes.sub,
            },
          },
        },
        authMode: authMode,
      })

      items = query?.data?.contractsByOrganization?.items

      console.log("refreshed contract items", items)
      setContractInfo(items)
    } catch (err) {
      console.log("refreshing contract error", err)
    }
  }
  const getContractInfo = async (calendarInfo: CalendarInfo) => {
    return new Promise(resolve => {
      let contractsArray: Contract[] = []
      if (calendarInfo) {
        calendarInfo.contracts &&
          calendarInfo.contracts.map(contract => {
            contractsArray.push(contract)
          })
      }
      setContractInfo(contractsArray)
      resolve(contractsArray)
    })
  }

  const submitBooking = async (
    timeSlotInfo: TimeSlotInfo,
    chosenLocation: Location | undefined,
    teamID: string,
    startDate: string,
    endDate: string
  ) => {
    let authMode: GraphQLOptions["authMode"]
    let bookingResponse: any
    let bookingResult: any
    let contractId: string | undefined = ""
    let queryParams: any = {}
    let serviceArray: Service[] | undefined = serviceList
    let contractArray: Contract[] | undefined = contractInfo
    if (!serviceList || serviceList?.length === 0) {
      serviceArray = await getServicesInfo()
    }
    console.log("SUBMIT BOOKING: serviceList", serviceList)
    console.log("SUBMIT BOOKING: serviceArray", serviceArray)
    console.log("SUBMIT BOOKING: timeSlotInfo", timeSlotInfo)
    const bookingService: Service | undefined = serviceArray?.find(
      service => service.id === timeSlotInfo.serviceID
    )
    console.log("SUBMIT BOOKING: bookingService", bookingService)
    try {
      if (
        bookingService &&
        bookingService.billingType === BillingTypeEnum.NOCHARGE
      ) {
        const noChargeContractID = await createNoChargeContract(
          bookingService.id,
          bookingService.organizationID
        )
        console.log("SUBMIT BOOKING: noChargeContractID", noChargeContractID)
      }
    } catch (error) {
      console.log("BOOKINGCONTEXT: Error creating no charge contract", error)
    }
    if (timeSlotInfo.serviceID) {
      console.log(
        "SUBMIT BOOKING: calling getFilteredBookingInfo",
        timeSlotInfo.serviceID
      )
      await getFilteredBookingInfo([
        (bookingService && bookingService.id) || timeSlotInfo.serviceID,
      ])
    }

    if (!bookingService) {
      console.log("BOOKING RESULT ERROR !!!!!!!:  !bookingService")
      return { bookingResponse: BookingResults.ERROR }
    }
    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()
      // TO DO: handle case where timeslot info only has timeslotID instead of serviceID
      console.log("SUBMIT BOOKING: cognitoUserResponse", cognitoUserResponse)
      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
        const signedInUserId = cognitoUserResponse.attributes.sub

        contractArray = await getServiceContracts(timeSlotInfo?.serviceID || "")
        console.log("SUBMIT BOOKING: contractArray", contractArray)
        let activePaidContracts = contractArray?.filter(
          contract =>
            contract.active &&
            (contract.status === "PAID" || contract.status === "NOCHARGE")
        )
        console.log("SUBMIT BOOKING: activePaidContracts", activePaidContracts)
        const contractParam = activePaidContracts?.find(contract => {
          return (
            contract.serviceID === timeSlotInfo.serviceID &&
            contract.userID === signedInUserId
          )
        })
        contractId = contractParam?.id
        if (!contractId || contractId?.length < 1) {
          return {
            bookingResult: BookingResults.PURCHASE,
            bookingService: bookingService,
          }
        }
      }
    } catch (err) {
      console.log("BOOKING RESULT ERROR !!!!!!!:  unauthenticated")
      return {
        bookingResult: BookingResults.UNAUTHENTICATED,
        bookingService: bookingService,
      }
    }
    try {
      if (timeSlotInfo.baseType === "TeamAvailability" && contractId) {
        queryParams = {
          query: azingoBookingTeamAvailability,
          variables: {
            contractID: contractId,
            teamAvailabilityID: timeSlotInfo.teamAvailabilityID,
            teamID: teamID,
            startDate: startDate || timeSlotInfo.startDate,
            endDate: endDate || timeSlotInfo.endDate,
            location: stripTypename(chosenLocation),
          },
          authMode: authMode,
        }
        bookingResponse = await API.graphql(queryParams)
        console.log("BOOKING RESULT bookingResponse", bookingResponse)
        bookingResult = bookingResponse?.data?.azingoBookingTeamAvailability
        let bookingResultObject = bookingResult && JSON.parse(bookingResult)
        if (
          bookingResultObject &&
          (bookingResultObject.status == 200 ||
            bookingResultObject.status == 201)
        ) {
          setCalendarReloadRequested(true)
          //await clearPurchaseSession()
          return {
            bookingResult: BookingResults.SUCCESS,
            booking: bookingResultObject?.body?.booking,
            bookingService: bookingService,
          }
        } else {
          console.log("BOOKING RESULT ERROR !!!!!!!:  error0")
          return {
            bookingResult: BookingResults.ERROR,
            bookingService: bookingService,
          }
        }
      } else if (timeSlotInfo.baseType === "TimeSlot" && contractId) {
        queryParams = {
          query: azingoBookingTimeSlot,
          variables: {
            contractID: contractId,
            timeSlotID: [timeSlotInfo.timeSlotID],
            location: stripTypename(chosenLocation),
          },
          authMode: authMode,
        }
        bookingResponse = await API.graphql(queryParams)
        bookingResult = bookingResponse?.data?.azingoBookingTimeSlot
        let bookingResultObject = bookingResult && JSON.parse(bookingResult)
        if (
          bookingResultObject &&
          (bookingResultObject.status == 200 ||
            bookingResultObject.status == 201)
        ) {
          setCalendarReloadRequested(true)
          await clearPurchaseSession()
          return {
            bookingResult: BookingResults.SUCCESS,
            booking: bookingResultObject?.body?.booking,
            bookingService: bookingService,
          }
        } else {
          console.log("BOOKING RESULT ERROR !!!!!!!:  error1")
          return {
            bookingResult: BookingResults.ERROR,
            bookingService: bookingService,
          }
        }
      } else {
        console.log("BOOKING RESULT ERROR !!!!!!!:  error2")
        return {
          bookingResult: BookingResults.ERROR,
          bookingService: bookingService,
        }
      }
    } catch (err) {
      console.log("BOOKING RESULT ERROR !!!!!!!:  error", err)
      return {
        bookingResult: BookingResults.ERROR,
        bookingService: bookingService,
      }
    }
  }
  const submitScheduled = async (
    timeSlotIds: string[],
    serviceID: string,
    location: Location | undefined
  ) => {
    let authMode: GraphQLOptions["authMode"]
    let bookingResponse: any
    let bookingResult: any
    let contractId: string | undefined = ""
    let queryParams: any = {}
    let serviceArray: Service[] | undefined = serviceList
    let contractArray: Contract[] | undefined = contractInfo
    if (!serviceList || serviceList?.length === 0) {
      serviceArray = await getServicesInfo()
    }
    const bookingService: Service | undefined = serviceArray?.find(
      service => service.id === serviceID
    )

    try {
      if (
        bookingService &&
        bookingService.billingType === BillingTypeEnum.NOCHARGE
      ) {
        const noChargeContractID = await createNoChargeContract(
          bookingService.id,
          bookingService.organizationID
        )
        console.log("noChargeContractID", noChargeContractID)
      }
    } catch (error) {
      console.log("BOOKINGCONTEXT: Error creating no charge contract", error)
    }
    await getFilteredBookingInfo([serviceID])

    if (!bookingService) {
      return { bookingResponse: BookingResults.ERROR }
    }
    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()
      // TO DO: handle case where timeslot info only has timeslotID instead of serviceID
      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
        const signedInUserId = cognitoUserResponse.attributes.sub
        if (!contractArray) {
          contractArray = await getServiceContracts(serviceID || "")
        }
        console.log("contractArray", contractArray)
        let activePaidContracts = contractArray?.filter(
          contract =>
            contract.active &&
            (contract.status === "PAID" || contract.status === "NOCHARGE")
        )
        console.log("activePaidContracts", activePaidContracts)
        const contractParam = activePaidContracts?.find(contract => {
          return (
            contract.serviceID === serviceID &&
            contract.userID === signedInUserId
          )
        })
        contractId = contractParam?.id
        if (!contractId || contractId?.length < 1) {
          return {
            bookingResult: BookingResults.PURCHASE,
            bookingService: bookingService,
          }
        }
      }
    } catch (err) {
      return {
        bookingResult: BookingResults.UNAUTHENTICATED,
        bookingService: bookingService,
      }
    }
    try {
      if (
        bookingService.bookingType === BookingServiceType.SCHEDULED &&
        contractId
      ) {
        queryParams = {
          query: azingoBookingTimeSlot,
          variables: {
            contractID: contractId,
            timeSlotIDs: timeSlotIds,
            location: stripTypename(location),
          },
          authMode: authMode,
        }
        bookingResponse = await API.graphql(queryParams)
        bookingResult = bookingResponse?.data?.azingoBookingTimeSlot
        let bookingResultObject = bookingResult && JSON.parse(bookingResult)
        console.log("bookingResultObject", bookingResultObject)
        if (
          bookingResultObject &&
          (bookingResultObject.status == 200 ||
            bookingResultObject.status == 201)
        ) {
          setCalendarReloadRequested(true)
          await clearPurchaseSession()
          console.log("bookingResultObject0", bookingResultObject)
          return {
            bookingResult: BookingResults.SUCCESS,
            booking: bookingResultObject?.body?.booking,
            bookingService: bookingService,
          }
        } else {
          console.log("bookingResultObject1", bookingResultObject)
          return {
            bookingResult: BookingResults.ERROR,
            bookingService: bookingService,
          }
        }
      } else {
        console.log("no contractID found")
        return {
          bookingResult: BookingResults.ERROR,
          bookingService: bookingService,
        }
      }
    } catch (err) {
      console.log("general booking error", err)
      return {
        bookingResult: BookingResults.ERROR,
        bookingService: bookingService,
      }
    }
  }

  const createContractName = (
    email: string,
    name: string,
    given: string,
    family: string
  ) => {
    if (given && given.length > 0 && family && family.length > 0) {
      if (given.includes("undefined") || family.includes("undefined")) {
        return email
      } else {
        return given + " " + family
      }
    } else if (given && given.length > 0 && !family) {
      if (given.includes("undefined")) {
        return email
      } else {
        return given
      }
    } else if (family && family.length > 0 && !given) {
      if (family.includes("undefined")) {
        return email
      } else {
        return family
      }
    } else if (name && name.length > 0) {
      if (name.includes("undefined")) {
        return email
      } else {
        return name
      }
    } else {
      return email
    }
  }

  const createNoChargeContract = async (
    serviceID: string,
    organizationID: string
  ) => {
    let possibleToken
    let currentUserID
    let currentUserInfo
    try {
      possibleToken = (await Auth.currentSession()).getIdToken().getJwtToken()
      currentUserInfo = await Auth.currentUserInfo()
      currentUserID = currentUserInfo?.attributes?.sub
    } catch (error) {
      return []
    }
    try {
      const requestInfo = {
        headers: {
          Authorization: possibleToken,
          "Content-Type": "application/json",
        },
        body: {
          action: "create-nocharge-contract",
          userId: currentUserID,
          serviceId: serviceID,
          organizationId: organizationID,
          address: currentUserInfo?.attributes?.address,
          email: currentUserInfo?.attributes?.email,
          phone: currentUserInfo?.attributes?.phone_number,
          name: createContractName(
            currentUserInfo?.attributes?.email,
            currentUserInfo?.attributes?.email,
            currentUserInfo?.attributes?.given_name,
            currentUserInfo?.attributes?.family_name
          ),
        },
      }
      if (serviceID && currentUserID) {
        const resp = await API.put("azingorest", "/contract", requestInfo)
        return resp.body
      } else return {}
    } catch (error) {
      return {}
    }
  }
  // const getBookingInfo = async () => {
  //   let authMode: GraphQLOptions["authMode"]
  //   let signedInUserId = null

  //   try {
  //     const cognitoUserResponse = await Auth.currentAuthenticatedUser()

  //     if (cognitoUserResponse) {
  //       authMode = "AMAZON_COGNITO_USER_POOLS"
  //       signedInUserId = cognitoUserResponse.attributes.sub
  //     }
  //   } catch {
  //     authMode = "AWS_IAM"
  //   }
  //   try {
  //     const query: any = await API.graphql({
  //       query: azingoCalendarQuery,
  //       variables: {
  //         userID: signedInUserId,
  //         organizationID: `4035948d-763a-41e2-8600-1cdd71a68da5`,
  //         serviceIDs: serviceIds,
  //         startDate: moment().subtract(1, "d").toISOString(),
  //       },
  //       authMode: authMode,
  //     })

  //     const calendarInfo = query?.data?.azingoCalendarQuery
  //     setBookingInfo(JSON.parse(calendarInfo))
  //     console.log("serviceIDs", serviceIds)
  //     console.log("calendar info", JSON.parse(calendarInfo))
  //     const availabilityArray = generateAppointmentArraysFromCalendarData(
  //       JSON.parse(calendarInfo)
  //     )
  //     const contractInfo = getContractInfo(JSON.parse(calendarInfo))
  //     await Promise.all([contractInfo, availabilityArray])
  //   } catch (err) {
  //     console.log("query error", err)
  //   }
  // }

  const getFilteredBookingInfo = async (serviceIDArray: string[]) => {
    let authMode: GraphQLOptions["authMode"]
    let signedInUserId = null
    setLoadingCalendar(true)

    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()

      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
        signedInUserId = cognitoUserResponse.attributes.sub
      }
    } catch {
      authMode = "AWS_IAM"
    }
    try {
      const query: any = await API.graphql({
        query: azingoCalendarQuery,
        variables: {
          userID: signedInUserId,
          organizationID: `4035948d-763a-41e2-8600-1cdd71a68da5`,
          serviceIDs: serviceIDArray,
          startDate: moment().subtract(1, "d").toISOString(),
        },
        authMode: authMode,
      })

      const calendarInfo = query?.data?.azingoCalendarQuery
      setBookingInfo(JSON.parse(calendarInfo))
      console.log("signInUser", signedInUserId)
      console.log("yesterday", moment().subtract(1, "d").toISOString())
      console.log("serviceIDs", serviceIDArray)
      console.log("calendar info", JSON.parse(calendarInfo))
      const availabilityArray = generateAppointmentArraysFromCalendarData(
        JSON.parse(calendarInfo)
      )

      const contractInfo = getContractInfo(JSON.parse(calendarInfo))
      await Promise.all([contractInfo, availabilityArray])
      console.log("getFilteredBookingInfo availabilityArray", availabilityArray)
    } catch (err) {
      console.log("query error", err)
    } finally {
      setLoadingCalendar(false)
    }
  }

  const registerCustomer = async (props: RegistrationProps) => {
    const { serviceID, timeSlotID, locations, teamID } = props
    let email
    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()
      email = cognitoUserResponse.attributes.email
    } catch (error) {
      throw error
    }
    let query = {
      query: azingoBookingNewCustomer,
      variables: {
        serviceID: serviceID,
        timeSlotID: timeSlotID,
        email: email,
        bookingDate: moment().toISOString(),
        locations: locations,
        teamID: teamID,
      },
    }
    try {
      const { data } = await (API.graphql(query) as Promise<any>)
      if (data) {
        const batchBookings = JSON.parse(
          data.azingoBookingNewCustomer
        )?.batchBookings
        // if (batchBookings ) {
        //   const newBookingList = bookingInfo?.bookings?.concat(batchBookings)
        //   setBookingInfo(({bookings}) => ({
        //       bookings: [...batchBookings, ...bookings]
        //   }))
        // }
        return batchBookings
      } else {
      }
    } catch (error) {}
  }

  const validServiceLocation = (currentPurchaseSession: PurchaseSession) => {
    let nameMatches: boolean = false
    if (
      currentPurchaseSession?.service?.locationType ===
      ServiceLocationType.PROVIDERPREMESIS
    ) {
      currentPurchaseSession?.service?.locations?.map(location => {
        // console.log(
        //   "purchase session location name",
        //   currentPurchaseSession.location?.name
        // )
        // console.log("location name", location.name)
        // console.log(
        //   "location match?",
        //   currentPurchaseSession.location?.name === location.name
        // )
        if (currentPurchaseSession.location?.name === location.name) {
          const updatedFailures = failureReasons.filter(
            failures => failures !== BookingFailureReason.INVALID_LOCATION
          )
          // console.log("updatedFailures", updatedFailures)
          setFailureReasons(updatedFailures)
          nameMatches = true
        }
      })
      if (nameMatches) {
        return true
      } else {
        setFailureReasons(prevFailures => [
          ...prevFailures,
          BookingFailureReason.INVALID_LOCATION,
        ])
        return false
      }
    }
    return true
  }

  const bookingFieldsCompleteAndValid = (purchaseSession: PurchaseSession) => {
    // console.log("failureReasons", failureReasons)
    if (purchaseSession) {
      if (
        !purchaseSession.location &&
        !failureReasons.includes(BookingFailureReason.INVALID_LOCATION)
      ) {
        setFailureReasons(prevFailures => [
          ...prevFailures,
          BookingFailureReason.INVALID_LOCATION,
        ])
      } else {
        const updatedFailures = failureReasons.filter(
          failures => failures !== BookingFailureReason.INVALID_LOCATION
        )
        // console.log("updatedFailures", updatedFailures)
        setFailureReasons(updatedFailures)
      }
      if (
        (!purchaseSession.purchased ||
          purchaseSession?.service?.billingType === BillingTypeEnum.NOCHARGE) &&
        !failureReasons.includes(BookingFailureReason.INVALID_PAYMENT)
      ) {
        setFailureReasons(prevFailures => [
          ...prevFailures,
          BookingFailureReason.INVALID_PAYMENT,
        ])
      } else {
        const updatedFailures = failureReasons.filter(
          failures => failures !== BookingFailureReason.INVALID_PAYMENT
        )
        setFailureReasons(updatedFailures)
      }
      if (
        !purchaseSession.teamID &&
        !failureReasons.includes(BookingFailureReason.INVALID_TEAM)
      ) {
        setFailureReasons(prevFailures => [
          ...prevFailures,
          BookingFailureReason.INVALID_TEAM,
        ])
      } else {
        const updatedFailures = failureReasons.filter(
          failures => failures !== BookingFailureReason.INVALID_TEAM
        )
        setFailureReasons(updatedFailures)
      }
      if (
        !!purchaseSession.location &&
        (!!purchaseSession.purchased ||
          purchaseSession?.service?.billingType === BillingTypeEnum.NOCHARGE) &&
        !!purchaseSession.teamID
      ) {
        switch (purchaseSession.service?.bookingType) {
          case BookingServiceType.SCHEDULED:
            if (
              purchaseSession.scheduledTimeSlotIDs &&
              purchaseSession.scheduledTimeSlotIDs.length > 0
            ) {
              setFailureReasons([])
              return true
            } else {
              setFailureReasons(prevFailures => [
                ...prevFailures,
                BookingFailureReason.INVALID_SCHEDULE,
              ])
            }
            break
          case BookingServiceType.BOOKABLE:
            if (!!purchaseSession.startDate) {
              setFailureReasons([])
              return true
            } else {
              setFailureReasons(prevFailures => [
                ...prevFailures,
                BookingFailureReason.INVALID_SCHEDULE,
              ])
            }
            break
        }
      }
    }
    return false
  }

  const readyToConfirmBooking = (purchaseSession: PurchaseSession) => {
    setFailureReasons([])
    if (
      bookingFieldsCompleteAndValid(purchaseSession) &&
      validServiceLocation(purchaseSession)
    ) {
      return true
    }
    return false
  }

  // useEffect(() => {
  //   ;(async () => {
  //     setLoadingCalendar(true)
  //     await getBookingInfo()
  //     setLoadingCalendar(false)
  //   })()
  // }, [])

  useEffect(() => {
    const reloadBookingInfo = async () => {
      console.log("rebuilding calendar")
      if (chosenService) {
        await getFilteredBookingInfo([chosenService])
      }
      setLoadingCalendar(false)
      setCalendarReloadRequested(false)
      console.log("rebuilt calendar")
    }
    if (calendarReloadRequested && !loadingCalendar) {
      setLoadingCalendar(true)
      reloadBookingInfo()
    }
  }, [calendarReloadRequested])

  useEffect(() => {
    ;(async () => await getPurchases())()
  }, [contractInfo])

  // useEffect(() => {
  //   const loadBookings = async () => {
  //     let serviceIDArray: string[] = []
  //     if (serviceList && serviceList.length > 0) {
  //       serviceList.forEach(service => {
  //         serviceIDArray.push(service.id)
  //       })
  //       console.log("why? is this the serviceArray", serviceIDArray.slice(2))
  //       await getFilteredBookingInfo(serviceIDArray.slice(2))
  //     }
  //   }
  //   console.log("serviceList updated!", serviceList)
  //   loadBookings()
  // }, [serviceList])

  // useEffect(() => {
  //   getTimeSlotInfo()
  // }, [chosenDateKey])

  return (
    <BookingContext.Provider
      value={{
        getBookings,
        getFilteredBookingInfo,
        bookingInfo,
        contractInfo,
        todaysServiceIds,
        setTodaysServiceIds,
        setChosenService,
        refreshContractInfo,
        chosenService,
        setChosenDateKey,
        chosenDateKey,
        timeSlots,
        availabilities,
        availabilityWindows,
        submitBooking,
        submitScheduled,
        purchaseDisplay,
        registerCustomer,
        loadingCalendar,
        setCalendarReloadRequested,
        getPurchaseCustomerID,
        fetchTimeSlot,
        fetchBooking,
        cancelBooking,
        fetchBookingRestrictions,
        readyToConfirmBooking,
        failureReasons,
        userBookings,
      }}
    >
      {children}
    </BookingContext.Provider>
  )
}

export default BookingContextProvider
