import React, { createContext, useEffect, useState } from "react"
import { Amplify, API, Auth } from "aws-amplify"
import awsconfig from "../aws-exports"
import { GraphQLOptions } from "@aws-amplify/api-graphql"
import {
  azingoContentQuery,
  bookingsByOrganization,
  affiliateLinkByOrganization,
  affiliateProductByOrganization,
  serviceByOrg,
  getRecommendation,
  categoryByOrganization,
} from "../graphql/queries"
import { updateService } from "../graphql/mutations"
import {
  BookingTypeEnum,
  Service,
  ServiceRegistrationType,
  AffiliateProduct,
  AffiliateLink,
  Category,
  // ContentType,
} from "../constants/Service"
import { Location, LocationRestrictionType } from "../constants/Location"
import { Booking } from "../constants/Booking"
import {
  RecoItem,
  RecoItemType,
  Recommendation,
} from "../constants/Recommendation"

Amplify.configure(awsconfig)

interface ServiceState {
  serviceList: Service[] | undefined
  displayServices: Service[] | undefined
  setDisplayServices: (serviceArray: Service[]) => void
  displayAffiliateProducts: AffiliateProduct[] | undefined
  displayAzingoAffiliateProducts: AffiliateProduct[] | undefined
  setDisplayAffiliateProducts: (serviceArray: AffiliateProduct[]) => void
  affiliateProducts: AffiliateProduct[] | undefined
  servicesLoaded: boolean
  getServicesDownloads: (serviceIDs: string[], userId: string) => any
  isAddressServiced: (serviceID: string, location: Location) => boolean
  getServicesInfo: () => Promise<Service[]>
  getAffiliateProducts: () => Promise<AffiliateProduct[]>
  getAzingoAffiliateLinks: () => Promise<AffiliateLink[]>
  getServicesBookings: (serviceID: string) => Promise<Booking[]>
  isScheduledService: boolean
  isScheduledAllSessionsService: boolean
  updateServiceType: (currentService: Service) => void
  updateServiceAttributes: (
    serviceID: string,
    updatedAttributes: any
  ) => Promise<void>
  getRecoItems: (id: string) => Promise<RecoItem[] | undefined>
  getCategories: () => Promise<Category[]>
  displayCategories: Category[] | undefined
}

interface ServiceContextProps {
  children: React.ReactNode
}

export const ServiceContext = createContext<ServiceState>({
  serviceList: [],
  displayServices: [],
  setDisplayServices: () => {},
  displayAffiliateProducts: [],
  displayAzingoAffiliateProducts: [],
  setDisplayAffiliateProducts: () => {},
  affiliateProducts: [],
  servicesLoaded: false,
  isAddressServiced: () => true,
  getServicesDownloads: () => {},
  getServicesInfo: async () => [],
  getAffiliateProducts: async () => [],
  getAzingoAffiliateLinks: async () => [],
  getServicesBookings: async () => [],
  isScheduledService: false,
  isScheduledAllSessionsService: false,
  updateServiceType: () => undefined,
  updateServiceAttributes: async () => undefined,
  getRecoItems: async () => undefined,
  getCategories: async () => [],
  displayCategories: [],
})

const ServiceContextProvider = ({ children }: ServiceContextProps) => {
  const [serviceList, setServiceList] = useState<Service[]>()
  const [affiliateProducts, setAffiliateProducts] =
    useState<AffiliateProduct[]>()
  const [servicesLoaded, setServicesLoaded] = useState(false)
  const [isScheduledService, setIsScheduledService] = useState(false)
  const [isScheduledAllSessionsService, setIsScheduledAllSessionsService] =
    useState(false)

  const [displayServices, setDisplayServices] = useState<
    Service[] | undefined
  >()
  const [displayAffiliateProducts, setDisplayAffiliateProducts] = useState<
    AffiliateProduct[] | undefined
  >()
  const [displayAzingoAffiliateProducts, setDisplayAzingoAffiliateProducts] =
    useState<AffiliateProduct[] | undefined>()
  const [displayCategories, setDisplayCategories] = useState<
    Category[] | undefined
  >()

  const updateServiceType = (chosenService: Service) => {
    //  console.log("UPDATESERVICETYPE: chosenService", chosenService)
    if (chosenService.bookingType === BookingTypeEnum.SCHEDULED) {
      setIsScheduledService(true)
      //  console.log("UPDATESERVICETYPE: isServiceScheduled", true)
      if (
        chosenService.registrationType === ServiceRegistrationType.ALLSESSIONS
      ) {
        setIsScheduledAllSessionsService(true)
        // console.log("UPDATESERVICETYPE: isScheduledAllSessions", true)
      } else {
        setIsScheduledAllSessionsService(false)
        // console.log("UPDATESERVICETYPE: isScheduledAllSessions", false)
      }
    } else {
      setIsScheduledService(false)
      // console.log("UPDATESERVICETYPE: isServiceScheduled", false)
      setIsScheduledAllSessionsService(false)
      // console.log("UPDATESERVICETYPE: isScheduledAllSessions", false)
    }
  }

  const getCategories = async () => {
    let authMode: GraphQLOptions["authMode"]

    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()

      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch {
      authMode = "AWS_IAM"
    }
    try {
      console.log("...loading categories")
      let items: Category[] = []

      const query: any = await API.graphql({
        query: categoryByOrganization,
        variables: {
          organizationID: `4035948d-763a-41e2-8600-1cdd71a68da5`,
          filter: {
            isDeleted: { ne: true },
          },
        },
        authMode: authMode,
      })

      items = query?.data?.categoryByOrganization?.items
      console.log("loaded Categories", items)
      const trimmedCategories = items.map(rawCategory => {
        let temp = Object.assign({}, rawCategory)
        if (temp.name) {
          temp.name = temp.name.trim()
        }
        return temp
      })
      console.log("trimmed Categories", trimmedCategories)
      setDisplayCategories(trimmedCategories)
      return items
    } catch (err) {
      console.log("bad categories", err)
      const badReturn: Category[] = []
      return badReturn
    }
  }

  const getAffiliateProducts = async () => {
    let authMode: GraphQLOptions["authMode"]

    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()

      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch {
      authMode = "AWS_IAM"
    }
    try {
      let items: AffiliateProduct[] = []

      const query: any = await API.graphql({
        query: affiliateProductByOrganization,
        variables: {
          organizationID: `4035948d-763a-41e2-8600-1cdd71a68da5`,
        },

        authMode: authMode,
      })

      items = query?.data?.affiliateProductByOrganization?.items

      setAffiliateProducts(items)
      setDisplayAffiliateProducts(items)
      return items
    } catch (err) {
      const badReturn: AffiliateProduct[] = []
      return badReturn
    }
  }

  const getRecoItems = async (id: string) => {
    let recoArray: RecoItem[] | undefined = []
    let recommendation: Recommendation | undefined = undefined
    let authMode: GraphQLOptions["authMode"]

    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()

      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch {
      authMode = "AWS_IAM"
    }
    try {
      const query: any = await API.graphql({
        query: getRecommendation,
        variables: {
          id: id,
        },

        authMode: authMode,
      })

      recommendation = query?.data?.getRecommendation
    } catch (err) {
      console.log("unable to find recommendation")
    }

    const serviceRecos = serviceList?.filter(
      service =>
        recommendation && recommendation.serviceIDs?.includes(service.id)
    )
    const affiliateRecos = affiliateProducts?.filter(
      ap =>
        recommendation && recommendation.affiliateProductIDs?.includes(ap.id)
    )
    console.log("serviceRecos", serviceRecos)
    console.log("affiliateRecos", affiliateRecos)
    serviceRecos &&
      serviceRecos.forEach(service => {
        recoArray &&
          recoArray.push({
            id: service.id,
            name: service.name,
            description: service.description,
            type: RecoItemType.SERVICE,
            bookingType: service.bookingType,
            thumbnail: service.thumbnail,
            url: "/buy/product/" + service.handle + "/",
          })
      })
    affiliateRecos &&
      affiliateRecos.forEach(ap => {
        recoArray &&
          recoArray.push({
            id: ap.id,
            name: ap.title,
            description: ap.description,
            type: RecoItemType.AFFILIATEPRODUCT,
            thumbnail: ap.thumbnail,
            url: ap.src,
          })
      })
    console.log("recoArray", recoArray)
    return recoArray
  }

  const getAzingoAffiliateLinks = async () => {
    let authMode: GraphQLOptions["authMode"]

    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()

      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch {
      authMode = "AWS_IAM"
    }
    try {
      let items: AffiliateLink[] = []

      const query: any = await API.graphql({
        query: affiliateLinkByOrganization,
        variables: {
          organizationID: `4035948d-763a-41e2-8600-1cdd71a68da5`,
        },
        // variables: {filter: {organizationID: {eq: "1e6b5f9b-7e77-4f90-b32a-31a751b477b9"}}, active: {eq: true}, isDeleted: {eq: false}},
        authMode: authMode,
      })

      items = query?.data?.affiliateLinkByOrganization?.items
      // console.log("serviceList Items", items)

      setDisplayAzingoAffiliateProducts(
        items.map(ap => ap.azingoAffiliateProduct)
      )
      return items
    } catch (err) {
      const badReturn: AffiliateLink[] = []
      return badReturn
    }
  }

  const getServicesInfo = async () => {
    let authMode: GraphQLOptions["authMode"]

    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()

      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch {
      authMode = "AWS_IAM"
    }
    try {
      let items: Service[] = []
      setServicesLoaded(false)

      const query: any = await API.graphql({
        query: serviceByOrg,
        variables: {
          organizationID: `4035948d-763a-41e2-8600-1cdd71a68da5`,
          filter: {
            active: { eq: true },
            isDeleted: { ne: true },
          },
        },
        // variables: {filter: {organizationID: {eq: "1e6b5f9b-7e77-4f90-b32a-31a751b477b9"}}, active: {eq: true}, isDeleted: {eq: false}},
        authMode: authMode,
      })

      items = query?.data?.serviceByOrg?.items
      console.log("serviceList Items", items)

      setServiceList(items.filter(item => item?.active === true))
      setDisplayServices(items.filter(item => item?.active === true))
      setServicesLoaded(true)
      return items.filter(item => item?.active === true)
    } catch (err) {
      console.log("serviceList error", err)
      const badReturn: Service[] = []
      return badReturn
    }
  }

  const getServicesBookings = async (serviceID: string) => {
    let authMode: GraphQLOptions["authMode"]

    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()

      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch {
      authMode = "AWS_IAM"
    }
    try {
      let items: Booking[] = []
      const query: any = await API.graphql({
        query: bookingsByOrganization,
        variables: {
          organizationID: `4035948d-763a-41e2-8600-1cdd71a68da5`,
          filter: {
            serviceID: { eq: serviceID },
            type: { in: ["CONFIRMED_UNPAID", "CONFIRMED_PAID"] },
          },
        },
        authMode: authMode,
      })

      items = query?.data?.bookingsByOrganization?.items

      return items
    } catch (err) {
      const badReturn: Booking[] = []
      return badReturn
    }
  }

  const getServicesDownloads = async (serviceIDs: string[], userID: string) => {
    let authMode: GraphQLOptions["authMode"]

    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()

      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch {
      authMode = "AWS_IAM"
    }
    try {
      const query: any = await API.graphql({
        query: azingoContentQuery,
        variables: {
          organizationID: `4035948d-763a-41e2-8600-1cdd71a68da5`,
          serviceIDs: serviceIDs,
          userID: userID,
        },
        // variables: {filter: {organizationID: {eq: "1e6b5f9b-7e77-4f90-b32a-31a751b477b9"}}, active: {eq: true}, isDeleted: {eq: false}},
        authMode: authMode,
      })

      const items = query?.data?.azingoContentQuery
      console.log("content downloads:", items)
      return items
    } catch (err) {
      const badReturn: any = {}
      console.log("content downloads:", err)
      return badReturn
    }
  }

  const isAddressServiced = (serviceID: string, location: Location) => {
    try {
      const service = findServiceByID(serviceID)
      console.log("addressService ServiceID", serviceID)
      console.log("addressService Service", service)

      const locationRestrictions = service?.locationRestrictions
      console.log("locationRestrictions", locationRestrictions)
      if (locationRestrictions && locationRestrictions.length > 0) {
        for (const restriction of locationRestrictions) {
          if (restriction.type === LocationRestrictionType.ADDRESSMATCH) {
            return isLocationCriteriaMatch(
              location,
              restriction.addressReference,
              restriction.addressCriteria || [""]
            )
          } else if (
            restriction.type === LocationRestrictionType.ADDRESSBLOCK
          ) {
            return !isLocationCriteriaMatch(
              location,
              restriction.addressReference,
              restriction.addressCriteria || [""]
            )
          } else {
            return true
          }
        }
      } else {
        return true
      }
      return true
    } catch {
      return true
    }
  }

  const isLocationCriteriaMatch = (
    location: Location,
    restrictionReference: string | undefined,
    restrictionCriteria: string[]
  ) => {
    switch (restrictionReference) {
      case "POSTALCODE":
        for (const criteria of restrictionCriteria) {
          // console.log("criteria", criteria)
          // console.log("location", location)
          if (
            location.postal_code
              ?.replace(/\s/g, "")
              ?.toLowerCase()
              .includes(criteria.replace(/\s/g, "")?.toLowerCase())
          )
            return true
        }
        // console.log("criteria failed")
        break
      case "COUNTRY":
        for (const criteria of restrictionCriteria) {
          if (
            location.country
              ?.replace(/\s/g, "")
              ?.toLowerCase()
              .includes(criteria.replace(/\s/g, "")?.toLowerCase())
          )
            return true
        }
        break
      case "STATE":
        for (const criteria of restrictionCriteria) {
          if (
            location.locality
              ?.replace(/\s/g, "")
              ?.toLowerCase()
              .includes(criteria.replace(/\s/g, "")?.toLowerCase())
          )
            return true
          if (
            location.region
              ?.replace(/\s/g, "")
              ?.toLowerCase()
              .includes(criteria.replace(/\s/g, "")?.toLowerCase())
          )
            return true
        }
        break
      default:
        return false
    }
    return false
  }

  const findServiceByID = (serviceID: string) => {
    console.log("addressService serviceList", serviceList)
    return serviceList?.find(service => service.id === serviceID)
  }

  const createServiceUpdateObject = (
    serviceID: string,
    serviceAttributes: any
  ) => {
    // const currentServiceObject = findServiceByID(serviceID)
    let returnAttributeObject: any = {
      id: serviceID,
    }
    if (serviceAttributes?.description) {
      returnAttributeObject["description"] = serviceAttributes?.description
    }
    if (serviceAttributes?.formattedDescription) {
      returnAttributeObject["formattedDescription"] =
        serviceAttributes?.formattedDescription
    }
    return { ...returnAttributeObject }
  }

  const updateServiceAttributes = async (
    serviceID: string,
    updatedAttributes: any
  ) => {
    const newServiceAttributes = createServiceUpdateObject(
      serviceID,
      updatedAttributes
    )
    console.log("newServiceAttributes", newServiceAttributes)
    try {
      const updateQuery = {
        query: updateService,
        variables: {
          input: newServiceAttributes,
        },
      }
      const {
        data: { updateService: response },
      } = await (API.graphql(updateQuery) as Promise<any>)
      console.log("Service Updated response", response)
      await getServicesInfo()
    } catch (err) {
      console.log(
        "Service Description update error",
        JSON.stringify(err, null, 2)
      )
    }
  }

  useEffect(() => {
    const loadServices = async () => {
      await getServicesInfo()
      // console.log("servicesloaded", serviceArray?.length)
      await getAffiliateProducts()
      await getAzingoAffiliateLinks()
      await getCategories()
    }
    loadServices()
  }, [])

  return (
    <ServiceContext.Provider
      value={{
        serviceList,
        displayServices,
        setDisplayServices,
        displayAffiliateProducts,
        displayAzingoAffiliateProducts,
        setDisplayAffiliateProducts,
        affiliateProducts,
        getAffiliateProducts,
        getAzingoAffiliateLinks,
        servicesLoaded,
        isAddressServiced,
        getServicesDownloads,
        getServicesInfo,
        getServicesBookings,
        isScheduledService,
        isScheduledAllSessionsService,
        updateServiceType,
        updateServiceAttributes,
        getRecoItems,
        getCategories,
        displayCategories,
      }}
    >
      {children}
    </ServiceContext.Provider>
  )
}

export default ServiceContextProvider
