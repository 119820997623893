import React, { createContext, useEffect, useState, useContext } from "react"
import { Amplify, API, Auth } from "aws-amplify"
import { GraphQLOptions } from "@aws-amplify/api-graphql"
import awsconfig from "../aws-exports"
import {
  BookingDisplay,
  PurchaseDisplay,
  Booking,
  ContractStatus,
} from "../constants/Booking"
import { ContractTypeEnum, Price, Service } from "../constants/Service"
import { BookingContext } from "./booking-context"
import {
  Contract,
  ProductPurchaseContract,
  ServiceRestrictions,
} from "../constants/Contract"
import { ServiceContext } from "./service-context"
import {
  getPrice,
  getContract,
  azingoContentQuery,
  bookingsByOrganization,
  getTimeSlot,
  contractsByUserID,
} from "../graphql/queries"
import { updateContract, createContract } from "../graphql/mutations"
import { TimeSlot } from "../constants/TimeSlot"
import moment from "moment"
import { Location as Address } from "../constants/Location"
Amplify.configure(awsconfig)

interface AccountState {
  purchaseDisplay: PurchaseDisplay[] | undefined
  loadAccountInfo: () => Promise<PurchaseDisplay[] | undefined>
  getServiceContracts: (serviceID: string) => Promise<Contract[]>
  getServiceContent: (serviceIDs: string[]) => Promise<any>
  loadingAccount: boolean
  setAccountReloadRequested: (reload: boolean) => void
  updateContractToPaid: (
    bookingID: string,
    contractID: string
  ) => Promise<boolean>
  updateContractWithPrice: (
    contractID: string,
    priceID: string
  ) => Promise<void>
  updateContractWithAddress: (
    contractID: string,
    address: Address
  ) => Promise<void>
  updateContractWithNotificationContacts: (
    contractID: string,
    contracts: string[]
  ) => Promise<void>
  getNotificationContract: (contractID: string) => Promise<Contract | undefined>
  getContracts: () => Promise<Contract[]>
  getContractsByUserID: () => Promise<Contract[]>
  getContractPrice: (priceID: string) => Promise<Price | undefined>
  getContractBookings: (
    contractID: string
  ) => Promise<BookingDisplay[] | undefined>
  createProductContract: (sessionData: ProductPurchaseContract) => Promise<void>
  getServiceRestrictions: (
    serviceID: string
  ) => Promise<ServiceRestrictions | undefined>
}

interface AccountContextProps {
  children: React.ReactNode
}

export const AccountContext = createContext<AccountState>({
  purchaseDisplay: undefined,
  loadAccountInfo: async () => undefined,
  getServiceContracts: async () => [],
  getServiceContent: async () => [],
  loadingAccount: false,
  setAccountReloadRequested: () => {},
  updateContractToPaid: async () => false,
  updateContractWithPrice: async () => {},
  updateContractWithAddress: async () => {},
  updateContractWithNotificationContacts: async () => {},
  getNotificationContract: async () => undefined,
  getContracts: async () => [],
  getContractsByUserID: async () => [],
  getContractPrice: async () => undefined,
  getContractBookings: async () => undefined,
  createProductContract: async () => undefined,
  getServiceRestrictions: async () => undefined,
})

const AccountContextProvider = ({ children }: AccountContextProps) => {
  const { serviceList } = useContext(ServiceContext)
  const { userBookings, contractInfo, refreshContractInfo } =
    useContext(BookingContext)
  const [purchaseDisplay, setPurchaseDisplay] = useState<PurchaseDisplay[]>()
  const [loadingAccount, setLoadingAccount] = useState(false)
  const [accountReloadRequested, setAccountReloadRequested] = useState(false)

  // const getDisplayTime = (fullDate: string | undefined) => {
  //   if (fullDate) {
  //     return moment(fullDate).local().format("h:mm A")
  //   } else {
  //     return undefined
  //   }
  // }

  const loadAccountInfo = async () => {
    let purchases
    try {
      // console.log("contractInfo", contractInfo)
      if (contractInfo) {
        purchases = await getPurchases(contractInfo, userBookings || [])
      }
      return purchases
    } catch (error) {
      console.log("loadAccountInfo error", error)
    }
  }

  const updateContractWithPrice = async (
    contractID: string,
    priceID: string
  ) => {
    let authMode: GraphQLOptions["authMode"] = "AMAZON_COGNITO_USER_POOLS"
    let cognitoUserResponse
    try {
      cognitoUserResponse = await Auth.currentAuthenticatedUser()
      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch (error) {
      authMode = "AWS_IAM"
    }

    if (cognitoUserResponse) {
      try {
        const updateQuery = {
          query: updateContract,
          variables: {
            id: contractID,
            priceID: priceID,
          },
          authMode: authMode,
        }
        await (API.graphql(updateQuery) as Promise<any>)
      } catch (error) {}
    }
  }

  const updateContractWithAddress = async (
    contractID: string,
    address: Address
  ) => {
    let authMode: GraphQLOptions["authMode"] = "AMAZON_COGNITO_USER_POOLS"
    let cognitoUserResponse
    try {
      cognitoUserResponse = await Auth.currentAuthenticatedUser()
      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch (error) {
      authMode = "AWS_IAM"
    }

    if (cognitoUserResponse) {
      try {
        const updateQuery = {
          query: updateContract,
          variables: {
            id: contractID,
            address: address,
          },
          authMode: authMode,
        }
        await (API.graphql(updateQuery) as Promise<any>)
      } catch (error) {}
    }
  }

  const updateContractWithNotificationContacts = async (
    contractID: string,
    contacts: string[]
  ) => {
    let authMode: GraphQLOptions["authMode"] = "AMAZON_COGNITO_USER_POOLS"
    let cognitoUserResponse
    try {
      cognitoUserResponse = await Auth.currentAuthenticatedUser()
      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch (error) {
      authMode = "AWS_IAM"
    }

    if (cognitoUserResponse) {
      try {
        const updateQuery = {
          query: updateContract,
          variables: {
            input: {
              id: contractID,
              notificationEmailList: contacts,
            },
          },
          authMode: authMode,
        }
        await (API.graphql(updateQuery) as Promise<any>)
        await refreshContractInfo()
      } catch (error) {
        console.log("error adding notification contacts", error)
      }
    }
  }

  const updateContractToPaid = async (
    bookingID: string,
    contractID: string
  ) => {
    let possibleToken
    try {
      possibleToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    } catch (error) {
      return true
    }
    try {
      const requestInfo = {
        headers: {
          Authorization: possibleToken,
          "Content-Type": "application/json",
        },
        body: {
          action: "pay-for-booking",
          bookingID: bookingID,
          contractID: contractID,
        },
      }
      await API.post("azingorest", "/booking", requestInfo)
      return true
    } catch (error) {
      return false
    }
  }

  const getContractsByUserID = async () => {
    let authMode: GraphQLOptions["authMode"]
    let userID = ""

    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()

      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
        userID = cognitoUserResponse.attributes.sub
      }
    } catch {
      return []
    }
    try {
      const getQuery = {
        query: contractsByUserID,
        variables: {
          userID: userID,
        },
        authMode: authMode,
      }
      const query: any = await API.graphql(getQuery)
      const contracts: Contract[] = query?.data?.contractsByUserID?.items
      console.log("contracts", contracts)
      const theseContracts = contracts.filter(
        contract => contract.organizationID === `4035948d-763a-41e2-8600-1cdd71a68da5`
      )
      return theseContracts
    } catch (err) {
      console.log("contractBy User ID Error", err)
      return []
    }
  }

  const getContracts = async () => {
    let possibleToken
    let currentUserID
    try {
      possibleToken = (await Auth.currentSession()).getIdToken().getJwtToken()
      const currentUserInfo = await Auth.currentUserInfo()
      currentUserID = currentUserInfo?.attributes?.sub
    } catch (error) {
      return []
    }
    try {
      const requestInfo = {
        headers: {
          Authorization: possibleToken,
          "Content-Type": "application/json",
        },
        body: {
          action: "list-user-contracts",
          userID: currentUserID,
        },
      }
      if (currentUserID) {
        const resp = await API.post("azingorest", "/contract", requestInfo)
        return resp.body
      } else return []
    } catch (error) {
      return []
    }
  }

  const getServiceRestrictions = async (serviceID: string) => {
    let restrictionResponse: ServiceRestrictions = {
      canBuy: true,
      requiredServices: [],
      prohibitedServices: [],
    }

    const currentProduct = serviceList?.find(
      service => service.id === serviceID
    )
    if (currentProduct) {
      const anyRequiredSubscriptions =
        currentProduct.requiredSubscriptions &&
        currentProduct.requiredSubscriptions.length > 0
      const anyProhibitedSubscriptions =
        currentProduct.prohibitedSubscriptions &&
        currentProduct.prohibitedSubscriptions.length > 0
      if (anyRequiredSubscriptions || anyProhibitedSubscriptions) {
        const activeContracts = await getContracts()
        if (anyRequiredSubscriptions) {
          currentProduct.requiredSubscriptions?.forEach(serviceID => {
            const servicesWithoutContracts =
              serviceList?.filter(service => {
                if (service.id === serviceID) {
                  if (activeContracts && activeContracts.length > 0) {
                    const activeContract = activeContracts.find(
                      (contract: Contract) => contract.serviceID === serviceID
                    )
                    return activeContract ? false : true
                  }
                  return true // don't have active contract
                }
                return false
              }) || []
            restrictionResponse.requiredServices = [
              ...new Set([
                ...restrictionResponse.requiredServices,
                ...servicesWithoutContracts,
              ]),
            ]
          })
        }
        if (anyProhibitedSubscriptions) {
          currentProduct.prohibitedSubscriptions?.forEach(serviceID => {
            const servicesWithContracts =
              serviceList?.filter(service => {
                if (service.id === serviceID) {
                  if (activeContracts && activeContracts.length > 0) {
                    const activeContract = activeContracts.find(
                      (contract: Contract) => contract.serviceID === serviceID
                    )
                    return activeContract ? true : false
                  }
                  return false // has no active contract
                }
                return false
              }) || []
            restrictionResponse.prohibitedServices = [
              ...new Set([
                ...restrictionResponse.prohibitedServices,
                ...servicesWithContracts,
              ]),
            ]
          })
        }
        if (
          (restrictionResponse.prohibitedServices &&
            restrictionResponse.prohibitedServices.length > 0) ||
          (restrictionResponse.requiredServices &&
            restrictionResponse.requiredServices.length > 0)
        ) {
          restrictionResponse.canBuy = false
        }
      }
    }
    console.log("Service Purchase Restrictions:", restrictionResponse)
    return restrictionResponse
  }

  const getServiceNameByID = (
    serviceID: string,
    services: Service[] | undefined
  ) => {
    if (services) {
      const serviceRecord = services.find(service => service.id === serviceID)
      if (serviceRecord) {
        return serviceRecord.name
      }
    }
    return "Service"
  }

  const getDisplayTime = (fullDate: string | undefined) => {
    if (fullDate) {
      return moment(fullDate).local().format("h:mm A")
    } else {
      return undefined
    }
  }

  const fetchTimeSlot = async (timeSlotID: string) => {
    let authMode: GraphQLOptions["authMode"]

    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()

      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch {
      authMode = "AWS_IAM"
    }
    try {
      const getQuery = {
        query: getTimeSlot,
        variables: {
          id: timeSlotID,
        },
        authMode: authMode,
      }
      const query: any = await API.graphql(getQuery)
      const timeSlot: TimeSlot = query?.data?.getTimeSlot
      return timeSlot
    } catch (err) {
      return undefined
    }
  }

  const getContractBookings = async (contractId: string) => {
    // let authMode = 'AMAZON_COGNITO_USER_POOLS'
    let cognitoUserResponse
    try {
      cognitoUserResponse = await Auth.currentAuthenticatedUser()
    } catch (error) {}
    if (cognitoUserResponse) {
      //   authMode = 'AMAZON_COGNITO_USER_POOLS'

      try {
        let bookingItems: Booking[] = []

        const result: any = await API.graphql({
          query: bookingsByOrganization,
          variables: {
            organizationID: `4035948d-763a-41e2-8600-1cdd71a68da5`,
            filter: {
              contractID: { eq: contractId },
            },
          },
        })
        //@ts-ignore
        bookingItems = result?.data?.bookingsByOrganization?.items

        // console.log("RAW BOOKING ITEMS", bookingItems)

        let bookingsArray: BookingDisplay[] = []

        console.log("bookingItems", bookingItems)

        bookingsArray = await Promise.all(
          bookingItems
            ?.filter(booking =>
              [
                "CONFIRMED_UNPAID",
                "CONFIRMED_PAID",
                "PENDING_TEAM",
                "WAITLIST",
                "CANCELED_CONTRACT",
              ].includes(booking.type)
            )
            ?.map(async booking => {
              let bookingEntry: any = {}
              bookingEntry.bookingID = booking.id
              bookingEntry.bookingStatus = booking.type
              console.log(
                "bookingEntry.bookingStatus",
                bookingEntry.bookingStatus
              )
              bookingEntry.bookingDate = booking.bookingDate
              bookingEntry.serviceName = getServiceNameByID(
                booking.serviceID,
                serviceList
              )
              console.log("bookingEntry.serviceName", bookingEntry.serviceName)
              bookingEntry.scheduledEndDate = booking?.timeSlot?.endDate
              bookingEntry.location = booking?.location
              if (booking.timeSlotID) {
                const bookingTimeSlot = await fetchTimeSlot(booking.timeSlotID)
                console.log("bookingTimeSlot", bookingTimeSlot)
                if (bookingTimeSlot) {
                  bookingEntry.scheduledDate = JSON.parse(
                    JSON.stringify(bookingTimeSlot)
                  ).startDate
                  console.log(
                    "bookingEntry.scheduledDate",
                    bookingEntry.scheduledDate
                  )
                  bookingEntry.scheduledTime = getDisplayTime(
                    bookingTimeSlot.startDate
                  )
                  console.log(
                    "bookingEntry.scheduledTime",
                    bookingEntry.scheduledTime
                  )
                }
              }
              bookingEntry.timeSlotID = booking.timeSlotID
              console.log("BookingRecord", bookingEntry)
              return bookingEntry
            })
        )

        console.log("RETURNED BOOKINGS ARRAY", bookingsArray)
        return bookingsArray
      } catch (error) {
        console.log("ERROR refreshing bookings", error)
      }
    }
  }

  function compareProperty(a: any, b: any) {
    return a || b ? (!a ? -1 : !b ? 1 : a.localeCompare(b)) : 0
  }

  function compareDates(a: any, b: any) {
    return (
      compareProperty(a?.purchaseDate, b?.purchaseDate) ||
      compareProperty(a?.booking?.scheduledDate, b?.booking?.scheduledDate)
    )
  }
  const getPurchases = async (
    contractArray: Contract[],
    bookings: BookingDisplay[]
  ) => {
    let purchaseArray: PurchaseDisplay[] = []
    try {
      for (const contract of contractArray) {
        let purchaseItem: PurchaseDisplay
        const serviceItem = serviceList?.filter(
          service => service.id === contract.serviceID
        )[0]
        if (serviceItem && contract.status !== "ABANDONED") {
          const selectedPrice = await getContractPrice(contract.priceID)
          purchaseItem = {
            serviceName: serviceItem.name,
            serviceDescription: serviceItem?.description,
            serviceHandle: serviceItem.handle,
            serviceUrl: serviceItem.thumbnail,
            purchaseDate: contract.createdAt,
            purchaseAmount: selectedPrice?.amount || 0,
            purchaseReference: contract.id,
            type: selectedPrice?.type,
            status: contract.status,
            frequency: selectedPrice?.frequency,
            active: contract.active,
            stripeCustomerID: contract.stripeCustomerID,
            stripePaymentIntentID: contract.stripePaymentIntentID,
            stripeSubscriptionID: contract.stripeSubscriptionID,
            contractID: contract.id,
          }
          if (bookings && bookings?.length > 0) {
            purchaseItem.booking = bookings
          }
          purchaseArray.push(purchaseItem)
        }
      }
      console.log("purchaseArray from query", purchaseArray)
      setPurchaseDisplay(purchaseArray.sort(compareDates))
      return purchaseArray
    } catch (error) {
      console.log("purchaseArray from query error", error)
      return []
    }
  }

  const createContractName = (
    email: string,
    name: string,
    given: string,
    family: string
  ) => {
    if (given && given.length > 0 && family && family.length > 0) {
      if (given.includes("undefined") || family.includes("undefined")) {
        return email
      } else {
        return given + " " + family
      }
    } else if (given && given.length > 0 && !family) {
      if (given.includes("undefined")) {
        return email
      } else {
        return given
      }
    } else if (family && family.length > 0 && !given) {
      if (family.includes("undefined")) {
        return email
      } else {
        return family
      }
    } else if (name && name.length > 0) {
      if (name.includes("undefined")) {
        return email
      } else {
        return name
      }
    } else {
      return email
    }
  }

  const createProductContract = async (
    sessionData: ProductPurchaseContract
  ) => {
    let userID
    let email
    let name
    let authMode: GraphQLOptions["authMode"]

    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()
      if (cognitoUserResponse) {
        userID = cognitoUserResponse.attributes.sub
        email = cognitoUserResponse.attributes.email
        name = createContractName(
          cognitoUserResponse.attributes.email,
          cognitoUserResponse.attributes.name,
          cognitoUserResponse.attributes.given_name,
          cognitoUserResponse.attributes.family_name
        )
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch {
      authMode = "AWS_IAM"
    }

    const createQuery = {
      query: createContract,
      variables: {
        input: {
          organizationID: `4035948d-763a-41e2-8600-1cdd71a68da5`,
          userID: userID,
          serviceID: sessionData.serviceID,
          stripePaymentIntentID: sessionData.stripePaymentIntentID,
          stripeCustomerID: sessionData.stripeCustomerID,
          activeDate: moment().toISOString(),
          baseType: "Contract",
          status: ContractStatus.PAID,
          name: name || sessionData.name,
          email: email,
          active: false,
          priceID: sessionData.priceID,
          duration: 1,
          type: ContractTypeEnum.PRODUCT_ONETIME,
        },
      },
      authMode: authMode,
    }

    try {
      const {
        data: { createContract: response },
      } = await (API.graphql(createQuery) as Promise<any>)
      console.log("new Product Contract created", response)
    } catch (error) {
      console.log("Error creating new Product contract", error)
    }
  }

  // const getServiceNameByID = (
  //   serviceID: string,
  //   services: Service[] | undefined
  // ) => {
  //   if (services) {
  //     const serviceRecord = services.find(service => service.id === serviceID)
  //     if (serviceRecord) {
  //       return serviceRecord.name
  //     }
  //   }
  //   return "Service"
  // }

  const getServiceContracts = async (serviceID: string) => {
    let possibleToken
    let currentUserID
    try {
      possibleToken = (await Auth.currentSession()).getIdToken().getJwtToken()
      const currentUserInfo = await Auth.currentUserInfo()
      currentUserID = currentUserInfo?.attributes?.sub
    } catch (error) {
      return []
    }
    try {
      const requestInfo = {
        headers: {
          Authorization: possibleToken,
          "Content-Type": "application/json",
        },
        body: {
          action: "list-user-service-contracts",
          userID: currentUserID,
          serviceID: serviceID,
        },
      }
      if (currentUserID && serviceID) {
        const resp = await API.post("azingorest", "/contract", requestInfo)
        return resp.body
      } else return []
    } catch (error) {
      return []
    }
  }

  const getServiceContent = async (serviceIDs: string[]) => {
    let authMode: GraphQLOptions["authMode"]
    let cognitoUserResponse
    try {
      cognitoUserResponse = await Auth.currentAuthenticatedUser()

      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch {
      authMode = "AWS_IAM"
    }
    try {
      const getQuery = {
        query: azingoContentQuery,
        variables: {
          organizationID: `4035948d-763a-41e2-8600-1cdd71a68da5`,
          serviceIDs: serviceIDs,
          userID: cognitoUserResponse?.attributes?.sub,
        },
        authMode: authMode,
      }
      const query: any = await API.graphql(getQuery)
      const contentItems = query?.data?.azingoContentQuery
      console.log(
        "contentItems service content",
        JSON.parse(contentItems)?.body
      )
      return JSON.parse(contentItems)?.body
    } catch (err) {
      console.log("error retrieving service content", err)
    }
    return undefined
  }

  const getContractPrice = async (priceID: string) => {
    let authMode: GraphQLOptions["authMode"]

    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()

      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch {
      authMode = "AWS_IAM"
    }
    if (priceID) {
      try {
        const getQuery = {
          query: getPrice,
          variables: {
            id: priceID,
          },
          authMode: authMode,
        }
        const query: any = await API.graphql(getQuery)
        const priceRecord = query?.data?.getPrice
        console.log("priceID", priceID)
        console.log("priceRecord", priceRecord)
        return priceRecord
      } catch (err) {
        console.log("Error retrieving Price from priceID", priceID)
        console.log("Error retrieving", err)
      }
    }
    return undefined
  }

  const getNotificationContract = async (contractID: string) => {
    let authMode: GraphQLOptions["authMode"]

    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()

      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch {
      authMode = "AWS_IAM"
    }
    try {
      const getQuery = {
        query: getContract,
        variables: {
          id: contractID,
        },
        authMode: authMode,
      }
      const query: any = await API.graphql(getQuery)
      const contractRecord = query?.data?.getContract
      console.log("Notification Contract", contractRecord)
      return contractRecord
    } catch (err) {
      console.log("error retrieving notificationcontract", err)
    }
    return undefined
  }

  useEffect(() => {
    const buildAccount = async () => {
      if (!loadingAccount) {
        setLoadingAccount(true)
        await loadAccountInfo()
        setLoadingAccount(false)
      }
    }
    console.log("loading account info")
    buildAccount()
  }, [contractInfo, userBookings, serviceList])

  // useEffect(() => {
  //   const buildAccount = async () => {
  //     if (!loadingAccount) {
  //       setLoadingAccount(true)
  //       await loadAccountInfo()
  //       setLoadingAccount(false)
  //     }
  //   }

  //   if (serviceList) {
  //     buildAccount()
  //   }
  // }, [serviceList])

  useEffect(() => {
    const buildAccount = async () => {
      if (!loadingAccount) {
        setLoadingAccount(true)
        await loadAccountInfo()
        setLoadingAccount(false)
        setAccountReloadRequested(false)
      }
    }
    if (serviceList && accountReloadRequested) {
      buildAccount()
    }
  }, [accountReloadRequested])

  return (
    <AccountContext.Provider
      value={{
        purchaseDisplay,
        loadAccountInfo,
        getServiceContracts,
        getServiceContent,
        loadingAccount,
        setAccountReloadRequested,
        updateContractToPaid,
        updateContractWithPrice,
        updateContractWithAddress,
        updateContractWithNotificationContacts,
        getNotificationContract,
        getContracts,
        getContractsByUserID,
        getContractPrice,
        getContractBookings,
        createProductContract,
        getServiceRestrictions,
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}

export default AccountContextProvider
