import React, { createContext, useContext, useState } from "react"
import { Amplify, API, Auth } from "aws-amplify"
import awsconfig from "../aws-exports"
import { loadStripe } from "@stripe/stripe-js"
import { getOrganization } from "../graphql/queries"
import { azingoCancelSubscription } from "../graphql/mutations"
import { ServiceContext } from "../contexts/service-context"
import { BookingContext } from "./booking-context"
import { AuthContext, CognitoUserExt } from "./auth-context"
import { ContractInfo } from "../constants/Contract"
// import { Price } from "../constants/Service"
import { GraphQLOptions } from "@aws-amplify/api-graphql"
import { AccountContext } from "./account-context"
import { Location as Address } from "../constants/Location"
import stripTypename from "../utils/strip-typename"
Amplify.configure(awsconfig)

export interface StripeCustomer {
  email: string
  name: string
  address: {
    city?: string
    country: string
    line1?: string
    line2?: string
    postal_code?: string
    state?: string
    phone?: string
  }
}
export interface CheckoutRequest {
  stripeAccount: string
  email?: string
  priceId: string
  quantity: number
  mode: string
  clientReferenceId?: string
  checkoutAddress?: Address
  organizationId: string
  serviceId: string
  cost?: number
  stripeCustomerId?: string
  customer?: StripeCustomer
  successPath?: string
  successAbsolutePath?: boolean
  cancelPath?: string
}

export interface Invoice {
  id: string
  amount_due?: number
  currency?: string
  description?: string
  lines: { data: InvoiceItems[] }
  status: string
  hosted_invoice_url: string
  created: number
}

export interface InvoiceItems {
  id: string
  amount: number
  currency: string
  description: string
  quantity: number
}

interface StripeState {
  getStripe: () => Promise<any>
  stripeAccount: string
  fetchStripeAccount: () => Promise<string>
  redirectToCheckout: (checkoutRequest: CheckoutRequest) => Promise<void>
  fetchCharges: (
    stripeCustomerId: string | undefined,
    limit: number
  ) => Promise<any[]>
  fetchInvoices: (
    stripeCustomerId: string | undefined,
    limit: number
  ) => Promise<any[]>
  cancelSubscription: (
    contractID: string | undefined,
    cancelReason: string | undefined
  ) => Promise<any>
  getOpenInvoices: () => Promise<Invoice[]>
  retrieveSession: (sessionID: string) => Promise<any>
}

interface StripeContextProps {
  children: React.ReactNode
}

export const StripeContext = createContext<StripeState>({
  getStripe: async () => undefined,
  stripeAccount: "",
  fetchStripeAccount: async () => "",
  redirectToCheckout: async () => {},
  fetchCharges: async () => [],
  fetchInvoices: async () => [],
  cancelSubscription: async () => {},
  getOpenInvoices: async () => [],
  retrieveSession: async () => undefined,
})

const StripeContextProvider = ({ children }: StripeContextProps) => {
  const [stripeAccount, setStripeAccount] = useState<string>("")
  const { serviceList } = useContext(ServiceContext)
  const { getPurchaseCustomerID } = useContext(BookingContext)
  const { defaultAddress, updateUserAttributes } = useContext(AuthContext)
  const { getContractPrice } = useContext(AccountContext)
  let stripePromise: any

  let spk: string = `${process.env.GATSBY_STRIPE_PUBLISHABLE_KEY}`!

  const getStripe = async () => {
    if (!stripePromise) {
      stripePromise = await loadStripe(spk)
    }
    return stripePromise
  }

  const createContract = async (contractInfo: ContractInfo) => {
    try {
      const action = contractInfo.action || "create-contract"
      const requestInfo = {
        headers: {
          Authorization: contractInfo.token,
          "Content-Type": "application/json",
        },
        body: {
          action: action,
          userId: contractInfo.userId,
          name: contractInfo.name,
          email: contractInfo.email,
          phone: contractInfo.phone,
          address: contractInfo.address,
          serviceId: contractInfo.serviceId,
          priceID: contractInfo.priceID,
          type: contractInfo.type,
          organizationId: contractInfo.organizationId,
        },
      }

      const resp = await API.put("azingorest", "/contract", requestInfo)
      const contractId = resp.body.contractId

      return contractId
    } catch (err) {
      console.log("contract error:", err)
      throw err
    }
  }

  // const getPriceID = async (stripePriceID: string) => {
  //   let cognitoUserResponse
  //   try {
  //     cognitoUserResponse = await Auth.currentAuthenticatedUser()
  //   } catch (error) {}
  //   if (cognitoUserResponse) {
  //     //   authMode = 'AMAZON_COGNITO_USER_POOLS'

  //     try {
  //       let nextToken = null
  //       let priceItems: Price[] = []
  //       do {
  //         const result: any = await API.graphql({
  //           query: listPrices,
  //           variables: {
  //             organizationID: `4035948d-763a-41e2-8600-1cdd71a68da5`,
  //             filter: {
  //               paymentRef: { eq: stripePriceID },
  //             },
  //             nextToken,
  //           },
  //         })
  //         //@ts-ignore
  //         priceItems = priceItems.concat(result?.data?.listPrices?.items)
  //         //@ts-ignore
  //         nextToken = result?.data?.listPrices?.nextToken
  //       } while (nextToken)
  //       return priceItems
  //     } catch (error) {}
  //   }
  // }

  const getOpenInvoices = async () => {
    const dummyInvoice: Invoice = {
      id: "one",
      description: "sample",
      lines: {
        data: [
          {
            id: "one",
            amount: 100,
            currency: "cad",
            description: "none",
            quantity: 1,
          },
        ],
      },
      status: "open",
      hosted_invoice_url: "string",
      created: 1,
    }
    const delayedFetch = async () => {
      setTimeout(() => {
        // console.log("gettingInvoices.")
      }, 1000)
    }
    await delayedFetch()

    return [dummyInvoice]
  }

  const updateUserProfileWithCheckoutAddress = async (
    checkoutAddress: Address | undefined
  ) => {
    if (checkoutAddress) {
      const cognitoAddress = JSON.stringify({
        locality: checkoutAddress?.locality,
        country: checkoutAddress?.country,
        street_address: checkoutAddress?.formatted,
        formatted: checkoutAddress?.street_address,
        postal_code: checkoutAddress?.postal_code,
        region: checkoutAddress?.region,
      })
      try {
        await updateUserAttributes({ address: cognitoAddress })
      } catch (error) {
        console.log("unable to update user address with:", cognitoAddress)
      }
    }
  }
  const createContractName = (
    email: string,
    name: string,
    given: string,
    family: string
  ) => {
    if (given && given.length > 0 && family && family.length > 0) {
      if (given.includes("undefined") || family.includes("undefined")) {
        return email
      } else {
        return given + " " + family
      }
    } else if (given && given.length > 0 && !family) {
      if (given.includes("undefined")) {
        return email
      } else {
        return given
      }
    } else if (family && family.length > 0 && !given) {
      if (family.includes("undefined")) {
        return email
      } else {
        return family
      }
    } else if (name && name.length > 0) {
      if (name.includes("undefined")) {
        return email
      } else {
        return name
      }
    } else {
      return email
    }
  }
  const redirectToCheckout = async (checkoutRequest: CheckoutRequest) => {
    try {
      const possibleToken = (await Auth.currentSession())
        .getIdToken()
        .getJwtToken()
      // const possibleAttributes = (await Auth.currentUserInfo())?.attributes
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()
      const { attributes } = cognitoUserResponse
      if (!attributes.address || JSON.stringify(attributes.address) === "{}") {
        await updateUserProfileWithCheckoutAddress(
          checkoutRequest.checkoutAddress
        )
      }
      const name = createContractName(
        attributes?.email,
        attributes?.name,
        attributes?.given_name,
        attributes?.family_name
      )
      const userId = attributes?.sub
      let customerAddress = defaultAddress
      if (
        checkoutRequest?.checkoutAddress &&
        JSON.stringify(checkoutRequest?.checkoutAddress) !== "{}"
      ) {
        customerAddress = JSON.parse(
          JSON.stringify(checkoutRequest?.checkoutAddress)
        )
      } else if (
        attributes?.address &&
        JSON.stringify(attributes?.address) !== "{}"
      ) {
        customerAddress = JSON.parse(attributes?.address)
      }

      customerAddress = stripTypename(customerAddress)

      // const customerAddressCountry = getAlpha2Code(
      //   customerAddress.country,
      //   "en"
      // )
      const customerAddressCountry =
        customerAddress?.country === "Canada" ? "CA" : "US"
      const customerEmail = attributes?.email
      const customerPhone = attributes?.phone_number
      const stripeCustomerId = getPurchaseCustomerID(userId)
      const relatedService = serviceList?.filter(
        service => service.id === checkoutRequest.serviceId
      )?.[0]
      const contractType = relatedService
        ? relatedService?.bookingType + "_" + relatedService?.billingType
        : undefined

      const priceObject = await getContractPrice(checkoutRequest.priceId)
      // const pricingList = await getPriceID(checkoutRequest.priceId)
      const serviceId = checkoutRequest.serviceId
      // console.log("STRIPECONTEXT: pricingList", pricingList)
      const contractInfo: ContractInfo = {
        type: contractType || "CUSTOMER",
        priceID: checkoutRequest.priceId,
        // pricingList && pricingList.length > 0 ? pricingList?.[0]?.id : "",
        userId: userId,
        name: name,
        email: customerEmail,
        phone: customerPhone,
        address: JSON.stringify(customerAddress),
        serviceId: checkoutRequest.serviceId,
        organizationId: checkoutRequest.organizationId,
        action: "create-contract",
        token: possibleToken,
      }
      const contractId = await createContract(contractInfo)
      const action = "fetch-session"
      const requestInfo = {
        headers: {
          Authorization: possibleToken,
          "Content-Type": "application/json",
        },
        body: {
          action: action,
          stripeAccount: checkoutRequest.stripeAccount,
          priceId: priceObject?.paymentRef,
          quantity: checkoutRequest.quantity,
          mode: checkoutRequest.mode,
          clientReferenceId: contractId,
          serviceId: serviceId,
          stripeCustomerId:
            checkoutRequest.stripeCustomerId || stripeCustomerId,
          customerEmail:
            checkoutRequest.stripeCustomerId || stripeCustomerId
              ? undefined
              : customerEmail || checkoutRequest.email,
          automaticTax: true,
          customer: {
            email: customerEmail || checkoutRequest.email,
            name: name,
            phone: customerPhone,
            address: {
              city: customerAddress?.locality,
              country: customerAddressCountry,
              line1: customerAddress?.street_address,
              postal_code: customerAddress?.postal_code,
              state: customerAddress?.region,
            },
          },
          successPath:
            checkoutRequest.successPath ||
            `services/cart/${relatedService?.handle}`,
          successAbsolutePath: checkoutRequest.successAbsolutePath || false,
          cancelPath:
            checkoutRequest.cancelPath ||
            `services/cart/${relatedService?.handle}`,
        },
      }

      console.log("checkoutRequest", checkoutRequest)
      console.log("requestInfo", requestInfo)
      const resp = await API.put("azingorest", "/stripe", requestInfo)
      const session = resp?.body?.session

      const sessionId = session?.id

      const checkoutStripePromise = loadStripe(spk, {
        stripeAccount: checkoutRequest.stripeAccount,
      })
      const stripe = await checkoutStripePromise
      stripe?.redirectToCheckout({ sessionId: sessionId })
    } catch (err) {
      console.log("error: ", err)
      throw err
    }
  }

  const fetchStripeAccount = async () => {
    let stripeAccountNumber: string = ""
    const query = {
      query: getOrganization,
      variables: {
        id: `4035948d-763a-41e2-8600-1cdd71a68da5`,
      },
    }
    try {
      const {
        data: {
          getOrganization: { paymentConfig },
        },
      } = await (API.graphql(query) as Promise<any>)

      if (paymentConfig) {
        stripeAccountNumber = JSON.parse(
          paymentConfig.toString()
        )?.stripeAccountID
        setStripeAccount(stripeAccountNumber)
      }
    } catch (error) {
      console.log("ERROR! unable to get stripe account", error)
    }
    return stripeAccountNumber
  }

  const fetchCharges = async (
    stripeCustomerId: string | undefined,
    limit: number = 50
  ) => {
    if (!stripeCustomerId) return []
    try {
      const possibleToken = (await Auth.currentSession())
        .getIdToken()
        .getJwtToken()

      const stripeAccount = await fetchStripeAccount()
      let requestInfo = {
        headers: {
          Authorization: possibleToken,
        },
        body: {
          action: "get-charges",
          accountId: stripeAccount,
          customerId: stripeCustomerId,
          limit: limit,
        },
      }

      const stripeChargesResponse = await API.post(
        "azingorest",
        "/stripe",
        requestInfo
      )
      const stripeCharges = stripeChargesResponse?.body?.data
      return stripeCharges
    } catch (error) {
      console.warn("Error retrieving customer charges from Stripe:", error)
    }
  }

  const fetchInvoices = async (
    stripeCustomerId: string | undefined,
    limit: number = 50
  ) => {
    if (!stripeCustomerId) return []
    try {
      const possibleToken = (await Auth.currentSession())
        .getIdToken()
        .getJwtToken()

      const stripeAccount = await fetchStripeAccount()
      let requestInfo = {
        headers: {
          Authorization: possibleToken,
        },
        body: {
          action: "get-invoices",
          accountId: stripeAccount,
          customerId: stripeCustomerId,
          invoiceStatus: undefined,
          limit: limit,
        },
      }

      const stripeInvoicesResponse = await API.post(
        "azingorest",
        "/stripe",
        requestInfo
      )
      const stripeInvoices = stripeInvoicesResponse?.body?.data
      return stripeInvoices
    } catch (error) {
      console.warn("Error retrieving customer charges from Stripe:", error)
    }
  }

  const retrieveSession = async (sessionId: string) => {
    try {
      const possibleToken = (await Auth.currentSession())
        .getIdToken()
        .getJwtToken()

      const stripeAccount = await fetchStripeAccount()
      let requestInfo = {
        headers: {
          Authorization: possibleToken,
        },
        body: {
          action: "retrieve-session",
          accountId: stripeAccount,
          sessionId: sessionId,
        },
      }

      const stripeSessionResponse = await API.post(
        "azingorest",
        "/stripe",
        requestInfo
      )
      const stripeSession = stripeSessionResponse?.body?.session
      return stripeSession
    } catch (error) {
      console.warn("Error retrieving session from Stripe:", error)
    }
  }

  const cancelSubscription = async (
    contractID: string | undefined,
    cancelReason: string | undefined
  ) => {
    let authMode: GraphQLOptions["authMode"] = "AMAZON_COGNITO_USER_POOLS"
    let cognitoUserResponse
    try {
      cognitoUserResponse = await Auth.currentAuthenticatedUser()
      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch (error) {
      authMode = "AWS_IAM"
    }

    if (cognitoUserResponse) {
      try {
        const updateQuery = {
          query: azingoCancelSubscription,
          variables: {
            contractID: contractID,
            cancelReason: cancelReason,
          },
          authMode: authMode,
        }
        await (API.graphql(updateQuery) as Promise<any>)
      } catch (error) {}
    }
  }

  return (
    <StripeContext.Provider
      value={{
        getStripe,
        stripeAccount,
        fetchStripeAccount,
        redirectToCheckout,
        fetchCharges,
        fetchInvoices,
        cancelSubscription,
        getOpenInvoices,
        retrieveSession,
      }}
    >
      {children}
    </StripeContext.Provider>
  )
}

export default StripeContextProvider
