import React, { createContext, useEffect, useState } from "react"
import { Amplify, API, Auth } from "aws-amplify"
import awsconfig from "../aws-exports"
import { GraphQLOptions } from "@aws-amplify/api-graphql"
import { websiteByOrganization } from "../graphql/queries"
import { updateWebsite } from "../graphql/mutations"
import { createTheme } from "@mui/material/styles"
import { Theme } from "@mui/material"
import defaultTheme from "../gatsby-theme-material-ui-top-layout/theme"

Amplify.configure(awsconfig)

export interface Testimonial {
  avatar?: string
  serviceID?: string
  customer: string
  company?: string
  comment: string
  createdAt?: string
}

export interface Social {
  facebook?: string
  twitter?: string
  instagram?: string
  discord?: string
  linkedin?: string
  tiktok?: string
  threads?: string
  website?: string
  youtube?: string
  contactEmail?: string
  contactPhone?: string
}

interface Menus {
  servicesLabel?: string
  productsLabel?: string
  testimonialLabel?: string
}

interface Buttons {
  availabilityLabel?: string
  learnLabel?: string
  moreLabel?: string
  watchLabel?: string
  viewLabel?: string
}

interface Config {
  categories?: string[]
  isDarkTheme?: boolean
  orgParametersList: any[]
  storage: string
}

interface WebContent {
  config?: Config
  heroSectionTitle?: string
  heroSectionContent?: string
  featureSectionActive?: boolean
  featureSectionTitle?: string
  aboutSectionActive?: boolean
  aboutSectionTitle?: string
  videoSectionActive?: boolean
  videoSectionTitle?: string
  articleSectionActive?: boolean
  articleSectionTitle?: string
  testimonialSectionActive?: boolean
  testimonialSectionTitle?: string
  featureSectionContent?: string
  aboutSectionContent?: string
  videoSectionContent?: string
  articleSectionContent?: string
  testimonialSectionContent?: string
  social?: Social
  menus?: Menus
  buttons?: Buttons
}

interface ColorPalette {
  primary: ColorTheme
  secondary: ColorTheme
  success?: ColorTheme
  info?: ColorTheme
  warning?: ColorTheme
  error?: ColorTheme
  text?: TextColorTheme
  background?: BackgroundColorTheme
}

interface BackgroundColorTheme {
  default?: string
  paper?: string
}

interface TextColorTheme {
  primary?: string
  secondary?: string
  disabled?: string
  buttonText?: string
}

interface ColorTheme {
  main: string
  dark: string
  light: string
  constrastText?: string
}

interface Images {
  gallery?: [GalleryImage]
  heroImage?: string
  aboutImage?: string
  logoImage?: string
  logoMobileImage?: string
  logoImageAttributes?: { logoHeight?: number; logoWidth?: number }
  logoMobileImageAttributes?: { logoHeight?: number; logoWidth?: number }
  heroImageAttributes?: { heroHeight?: number; heroWidth?: number }
  aboutImageAttributes?: { aboutHeight?: number; aboutWidth?: number }
  serviceImageAttributes?: { serviceHeight?: number; serviceWidth?: number }
}

export interface GalleryImage {
  src: string
  thumbnail: string
  thumbnailWidth: number
  thumbnailHeight: number
  tags?: any
  caption?: string
}
export interface WebInfo {
  id: string
  organizationID: string
  content: WebContent
  baseType: string
  privacyPolicy?: string
  termsAndConditions?: string
  testimonials?: Testimonial[]
  images?: Images
  palette?: ColorPalette
  config?: any
  release?: number
  name: string
  metrics?: any
  handle?: string
  template: string
  url: string
}
interface WebState {
  webInfo?: WebInfo
  theme: Theme
  editMode: boolean
  setEditMode: (editable: boolean) => void
  updateWebInfo: (updatedWebInfo: any) => Promise<void>
}

interface WebContextProps {
  children: React.ReactNode
}

export const WebContext = createContext<WebState>({
  webInfo: undefined,
  theme: createTheme(defaultTheme),
  editMode: false,
  setEditMode: () => {},
  updateWebInfo: async () => {},
})

const WebContextProvider = ({ children }: WebContextProps) => {
  const [webInfo, setWebInfo] = useState<WebInfo>()
  const [theme, setTheme] = useState<Theme>(createTheme(defaultTheme))
  const [editMode, setEditMode] = useState(false)

  const updateWebInfo = async (updatedWebInfo: any) => {
    const newWebInfoObject: WebInfo | undefined =
      createWebInfoUpdateObject(updatedWebInfo)

    try {
      const updateQuery = {
        query: updateWebsite,
        variables: {
          input: newWebInfoObject,
        },
      }
      const {
        data: { updateWebsite: response },
      } = await (API.graphql(updateQuery) as Promise<any>)
      setWebInfo(response)
      console.log("webInfo Updated response", response)
    } catch (err) {
      console.log("Website info update error", JSON.stringify(err, null, 2))
    }
  }

  const createWebInfoUpdateObject = (webInfoAttributes: any) => {
    let returnAttributeObject: any = {
      id: webInfoAttributes?.id || webInfo?.id,
    }
    if (webInfoAttributes?.images) {
      let newImagesObject = { ...webInfo?.images, ...webInfoAttributes?.images }
      returnAttributeObject["images"] = JSON.stringify(newImagesObject)
    } else {
      returnAttributeObject["images"] = JSON.stringify(webInfo?.images)
    }
    if (webInfoAttributes?.content) {
      let newContentObject = {
        ...webInfo?.content,
        ...webInfoAttributes?.content,
      }

      returnAttributeObject["content"] = JSON.stringify(newContentObject)
    } else {
      returnAttributeObject["content"] = JSON.stringify(webInfo?.content)
    }
    if (webInfoAttributes?.palette) {
      let newPaletteObject = {
        ...webInfo?.palette,
        ...webInfoAttributes?.palette,
      }
      returnAttributeObject["palette"] = newPaletteObject
    }
    if (
      webInfoAttributes?.testimonials &&
      Object.keys(webInfoAttributes?.testimonials).length > 0
    ) {
      let newTestimonialsObject = {
        ...webInfo?.testimonials,
        ...webInfoAttributes?.testimonials,
      }
      returnAttributeObject["testimonials"] = newTestimonialsObject
    }
    if (webInfoAttributes?.privacyPolicy) {
      let newPrivacyPolicyObject = webInfoAttributes?.privacyPolicy
      returnAttributeObject["privacyPolicy"] = newPrivacyPolicyObject
    }
    if (webInfoAttributes?.termsAndConditions) {
      let newTermsAndConditionsObject = webInfoAttributes?.termsAndConditions
      returnAttributeObject["termsAndConditions"] = newTermsAndConditionsObject
    }

    return { ...webInfo, ...returnAttributeObject }
  }

  const getWebInfo = async () => {
    let authMode: GraphQLOptions["authMode"]
    let webInfoContent: WebContent
    let webInfoImages: Images

    try {
      const cognitoUserResponse = await Auth.currentAuthenticatedUser()

      if (cognitoUserResponse) {
        authMode = "AMAZON_COGNITO_USER_POOLS"
      }
    } catch {
      // console.log("anonymous user")
      authMode = "AWS_IAM"
    }
    try {
      const query: any = await API.graphql({
        // query: listWebsites,
        // variables: {
        //   filter: {
        //     organizationID: { eq: `4035948d-763a-41e2-8600-1cdd71a68da5` },
        //   },
        // },
        query: websiteByOrganization,
        variables: { organizationID: `4035948d-763a-41e2-8600-1cdd71a68da5` },
        authMode: authMode,
      })

      if (query) {
        const websiteContentRaw =
          query?.data?.websiteByOrganization?.items[0]?.content
        webInfoContent =
          typeof websiteContentRaw == "string"
            ? JSON.parse(websiteContentRaw)
            : websiteContentRaw
        const websiteImagesRaw =
          query?.data?.websiteByOrganization?.items[0]?.images
        webInfoImages =
          typeof websiteImagesRaw == "string"
            ? JSON.parse(websiteImagesRaw)
            : websiteImagesRaw
        const webInfoTestimonials =
          query?.data?.websiteByOrganization?.items[0]?.testimonials
        const webInfoPalette =
          query?.data?.websiteByOrganization?.items[0]?.palette
        const webInfoConfig =
          query?.data?.websiteByOrganization?.items[0]?.config
        const webInfoID = query?.data?.websiteByOrganization?.items[0]?.id
        const webInfoRelease =
          query?.data?.websiteByOrganization?.items[0]?.release
        const webInfoName = query?.data?.websiteByOrganization?.items[0]?.name
        const webInfoURL = query?.data?.websiteByOrganization?.items[0]?.url
        const webPrivacyPolicy =
          query?.data?.websiteByOrganization?.items[0]?.privacyPolicy
        const webTermsAndConditions =
          query?.data?.websiteByOrganization?.items[0]?.termsAndConditions
        getCustomTheme(webInfoPalette)
        // console.log("webcontent", query)
        setWebInfo({
          id: webInfoID,
          organizationID: `4035948d-763a-41e2-8600-1cdd71a68da5`,
          baseType: "Website",
          content: webInfoContent,
          images: webInfoImages,
          testimonials: webInfoTestimonials,
          privacyPolicy: webPrivacyPolicy,
          termsAndConditions: webTermsAndConditions,
          palette: webInfoPalette,
          config: webInfoConfig,
          release: webInfoRelease,
          name: webInfoName,
          template: "Gatsby",
          url: webInfoURL,
        })
        console.log("webInfo", query?.data)
      }
    } catch (err) {
      console.log("web error", err)
    }
  }

  const getCustomTheme = (overridePalette: ColorPalette) => {
    const customTheme = createTheme({
      typography: {
        subtitle1: {
          color: "#7D7987",
        },
      },
      palette: {
        primary: {
          main:
            overridePalette?.primary?.main || defaultTheme.palette.primary.main,
          light:
            overridePalette?.primary?.light ||
            defaultTheme.palette.primary.light,
          dark:
            overridePalette?.primary?.dark || defaultTheme.palette.primary.dark,
          contrastText: "#fff",
        },
        secondary: {
          main:
            overridePalette?.secondary?.main ||
            defaultTheme.palette.secondary.main,
          light:
            overridePalette?.secondary?.light ||
            defaultTheme.palette.secondary.light,
          dark:
            overridePalette?.secondary?.dark ||
            defaultTheme.palette.secondary.dark,
          contrastText: "#fff",
        },
        error: {
          main: overridePalette?.error?.main || defaultTheme.palette.error.main,
          dark: overridePalette?.error?.dark || defaultTheme.palette.error.dark,
          light:
            overridePalette?.error?.light || defaultTheme.palette.error.light,
        },
        background: {
          default: overridePalette?.background?.default || "#fff",
        },
        text: {
          primary: "#000",
          secondary: "#7D7987",
          disabled: "#CCC",
          buttonText: defaultTheme.palette.text.buttonText,
        },
        success: {
          main:
            overridePalette?.success?.main || defaultTheme.palette.success.main,
          light:
            overridePalette?.success?.light ||
            defaultTheme.palette.success.light,
          dark:
            overridePalette?.success?.dark || defaultTheme.palette.success.dark,
          contrastText: "#fff",
        },
        info: {
          main: overridePalette?.info?.main || defaultTheme.palette.info.main,
          light:
            overridePalette?.info?.light || defaultTheme.palette.info.light,
          dark: overridePalette?.info?.dark || defaultTheme.palette.info.dark,
          contrastText: "#fff",
        },
        warning: {
          main:
            overridePalette?.warning?.main || defaultTheme.palette.warning.main,
          light:
            overridePalette?.warning?.light ||
            defaultTheme.palette.warning.light,
          dark:
            overridePalette?.warning?.dark || defaultTheme.palette.warning.dark,
          contrastText: "#fff",
        },
      },
      shape: {
        borderRadius: 20,
      },
    })
    setTheme(customTheme)
  }
  useEffect(() => {
    getWebInfo()
  }, [])

  return (
    <WebContext.Provider
      value={{ webInfo, theme, editMode, setEditMode, updateWebInfo }}
    >
      {children}
    </WebContext.Provider>
  )
}

export default WebContextProvider
